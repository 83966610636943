import React, { Fragment, useEffect, useRef, useState } from "react";
import EditorText from "./EditorText";
import CustomModal from "../modals/CustomModal";
import Icons from "../Icons";
import { useDispatch, useSelector } from "react-redux";
import ImageUrlList from "../../manager/leads/components/ImageUrlList";
import Buttons from "../buttons/Buttons";
import { uploadImageAction } from "../../../services/action/common";
import { extractAllHrefsFromFileList, getFirstElementClassNameFromHTML } from "../functions/functions";

const Content = ({onClose,sendMessage,currentData, parentDivClassName,value, setValue, imageUrls, setImageUrls }) => {
  const fileRef = useRef();
  const dispatch = useDispatch();
  const { isImageLoading } = useSelector((state) => state.commonReducer);

  const uploadImage = (e) => {
    const callBackImage = (res) => {
      let tempData = [...imageUrls];
      setImageUrls([...tempData, ...res?.imageUrls]);
    };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  const onSubmit=()=>{
    let messageFile = "";
    imageUrls?.map((item) => {
      messageFile =
        messageFile +
        `<a target='_blank' href=${item}><i class="fa fa-file" aria-hidden="true"></i></a>`;
    });
    const finalMessage=`<div class="${parentDivClassName}">${value}<p class="file-list">${messageFile}</p> </div>`
    sendMessage(finalMessage,currentData?.isMasking,currentData?.onlyFor)
    onClose()
  }
  return (
    <Fragment>
      <form className="form">
        <div className="row">
          <div className="col-12">
            <label>Message</label>
            <EditorText value={value} setValue={setValue} />
          </div>
          <div className="col-12">
            <div
              className="delivery-box-input-file mt-3"
              onClick={() => fileRef.current?.click()}
            >
              <p className="m-0 text-center">Upload File</p>
              {isImageLoading && (
                <p className="m-0 text-center">
                  <Icons title={"loader"} color="#0a376a" />
                </p>
              )}
            </div>
            <input
              type="file"
              onChange={uploadImage}
              className="display-none"
              ref={fileRef}
              multiple={true}
            />
            {!!imageUrls?.length && (
              <ImageUrlList
                items={imageUrls}
                onDelete={(image) => {
                  let filterDocument = imageUrls?.filter(
                    (item) => item !== image
                  );
                  setImageUrls(filterDocument);
                }}
              />
            )}
          </div>
          <div className="col-12 text-center">
            <Buttons onClick={onSubmit} title={"Update Message"} />
          </div>
        </div>
      </form>
    </Fragment>
  );
};

const MessageEditor = ({ currentData,open, onClose, message,sendMessage }) => {
  const [value, setValue] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [parentDivClassName,setParentDivClassName]=useState("");
  useEffect(() => {
    const fileLink = extractAllHrefsFromFileList(message);
    const tempParentDivClassName=getFirstElementClassNameFromHTML(message);
    setParentDivClassName(tempParentDivClassName)
    setValue(message);
    setImageUrls(fileLink);
  }, [message]);

  return (
    <Fragment>
      <CustomModal
        size="md"
        zIndex={10000}
        open={open}
        onClose={onClose}
        content={() => (
          <Content
            currentData={currentData}
            sendMessage={sendMessage}
            onClose={onClose}
            imageUrls={imageUrls}
            setImageUrls={setImageUrls}
            value={value}
            setValue={setValue}
            parentDivClassName={parentDivClassName}
          />
        )}
      />
    </Fragment>
  );
};

export default MessageEditor;
