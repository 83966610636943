import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { verifyGoogleTokenAction } from '../../../services/action/authentication';
import { Container, CircularProgress, Typography, Box } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import GoogleSignInButton from './GoogleSignInButton';
import ProfileCompletionForm from './ProfileCompletionForm';
import { styled } from '@mui/material/styles';
import { GoogleButtonContent,GoogleButtonBackground, GoogleButtonWrapper, handleGoogleButtonHover, handleGoogleButtonLeave } from './StyledButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkLogin } from '../../../components/utils/functions/functions';

const StyledPaper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: '#ffffff',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
}));


const LoginWithGoogle = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const buttonControls = useAnimation();
  const isAndroidApp = queryParams.get("clickinpedia");
  const navigate=useNavigate();
  const handleCallbackResponse = (response) => {
    if (!response.credential) {
      console.error('No credential returned from Google');
      return;
    }

    setIsLoading(true);
    try {
      const userObject = JSON.parse(atob(response.credential.split('.')[1]));
      localStorage.setItem('token', response.credential);
      const payload = { token: response.credential };

      const callBack=(res)=>{
        if(res?.userType=="CLIENT" && res?.token && isAndroidApp){
          const url = "myapp://Login?token=" + res?.token;
          window.location.href = url;
        }else if(res?.token){
          checkLogin(navigate, res);
        }else{
          setUserInfo(userObject);
          setIsVerified(true);
        }
      }
      dispatch(verifyGoogleTokenAction({ data: payload,cb:callBack }));
    } catch (error) {
      console.error('Error decoding token or extracting user data:', error);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(()=>{
   const checkUserVerify=localStorage.getItem("emailVerifyToken");
   if(checkUserVerify){
   setUserInfo(JSON.parse(checkUserVerify))
   setIsVerified(true)
   }
  },[])
  return (
    <Box sx={{ 
      // background: "#0a376a",
       minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <Container component="main" maxWidth="xs" className='google-register-area'>
        <StyledPaper component={motion.div} initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          {!isVerified ? (
            <>
              <Typography component="h1" variant="h5" gutterBottom>
                Google
              </Typography>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <GoogleButtonWrapper
                onMouseEnter={()=>handleGoogleButtonHover(buttonControls)}
                onMouseLeave={()=>handleGoogleButtonLeave(buttonControls)}
                  animate={buttonControls}
                >
                  <GoogleButtonBackground
                    initial={{ x: '-100%' }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.3 }}
                  />
                  <GoogleButtonContent>
                    <GoogleSignInButton onLoginSuccess={handleCallbackResponse} />
                  </GoogleButtonContent>
                </GoogleButtonWrapper>
              )}
            </>
          ) : (
            <ProfileCompletionForm userInfo={userInfo} />
          )}
        </StyledPaper>
      </Container>
    </Box>
  );
};

export default LoginWithGoogle;