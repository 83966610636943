import React, { useState } from "react";
import Icons from "../utils/Icons";
import ClickToCall from "./CallNow";
import { useSelector } from "react-redux";
import UserContactDetails from "./contact-details/UserContactDetails";

const PersonalCard = ({
  close,
  data,
  onEditClick,
  onDeleteClick,
  hideAction,
}) => {
  const [showActions, setShowActions] = useState(false);
  const { user } = useSelector((state) => state.authenticationReducer);
  const isManagerOrAdminOrOperator =
    user?.userType === "MANAGER" ||
    user?.userType === "ADMIN" ||
    user?.userType === "OPERATOR" ||
    user?.userType === "SUPER_ADMIN";
  const image =
    "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg";

  return (
    <div className="flex justify-center items-center min-h-screen ">
      <div className="w-full max-w-sm bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="bg-[#00234a] h-24 relative text-right">
          <button
            className="relative top-4 left-4 text-white mr-21"
            onClick={close}
          >
            <Icons title={"cross"} />
          </button>
          <img
            className="w-20 h-20 rounded-full absolute bottom-0 left-4 transform translate-y-1/2 border-4 border-white object-cover"
            src={data?.image || image}
            alt="Olivia Wang"
          />
        </div>

        <div className={`pt-10 px-4 ${!hideAction && "pb-4"}`}>
          <h2 className="text-xl font-bold text-gray-800">{data?.name}</h2>
          <p className="text-sm text-gray-600">
            {data?.userType} • {data?.departMent || data?.expertType || ""}
          </p>

          <div className="mt-4 mb-4">
            <button className="bg-gray-100 text-[#00234a] text-sm py-1 px-3 rounded-full hover:bg-gray-200 transition duration-300">
              {data?.number}
            </button>
          </div>

          {!hideAction && (
            <div className="relative">
              <button
                onClick={() => setShowActions(!showActions)}
                className="bg-[#00234a] text-white text-sm py-1 px-4 rounded-md hover:bg-opacity-90 transition duration-300"
              >
                Actions ▼
              </button>
              {showActions && (
                <div className="absolute left-0 mt-2 w-32 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                  <button
                    type="button"
                    onClick={() => onEditClick(data)}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Edit
                  </button>
                  {data?._id && (
                    <button
                      type="button"
                      onClick={() => onDeleteClick(data?._id)}
                      className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                    >
                      Delete
                    </button>
                  )}
                </div>
              )}
              {user?.userType === "MANAGER" && (
                <ClickToCall phoneNumber={data?.countryCode + data?.number} />
              )}
            </div>
          )}

          {isManagerOrAdminOrOperator && data?.contactDetails && (
            <UserContactDetails contactDetails={data?.contactDetails}/>
          )}
        </div>

        <div className="border-t border-gray-200 px-4 py-4">
          <div className="flex items-center justify-between mb-2">
            <h3 className="font-semibold text-gray-700">Information</h3>
            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg> */}
          </div>
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              <span className="font-bold">Email:</span> {data?.email}
            </p>
            {/* <p className="text-sm text-gray-600"><span className="font-bold">Office ID:</span> OW12345</p> */}
            <p className="text-sm text-gray-600">
              <span className="font-bold">Status:</span> {data?.status}
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-bold">Department:</span> {data?.userType}
            </p>
            {/* <p className="text-sm text-gray-600"><span className="font-bold">Location:</span> San Francisco, CA</p> */}
            <p className="text-sm text-gray-600">
              <span className="font-bold">Joined:</span>{" "}
              {data?.createdAt || new Date().toUTCString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalCard;
