import React, { useEffect, useState } from "react";
import OrderListComponent from "../../components/manager/leads/OrderListComponent";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsAction } from "../../services/action/manager";

const OrderListExpert = () => {
  const dispatch = useDispatch();
  const { leads } = useSelector((state) => state.managerReducer);
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 15,
  });
  const [filters, setFilter] = useState(["ALL_ORDERS"]);

  const getList = ({
    currentPage = pageData?.page,
    firstTime = false,
    isEmptylist = true,
    search = "",
    filter = filters,
    sortConfig,
    dateFilter,
  }) => {
    dispatch(
      getLeadsAction({
        userType: "expert",
        firstTime,
        data: {
          sortConfig,
          search,
          page: currentPage,
          isEmptylist,
          dateFilter,
          orderStatus: filter,
        },
      })
    );
  };
  
  useEffect(() => {
    getList({ currentPage: 1,firstTime: true });
  }, []);
  return (
    <DashboardLayout onSearch={(value) => {
      setPageData({page:1})
      getList({ search: value,firstTime:true,currentPage:1})
    }}>
      <OrderListComponent
        getAllLiveUser={getList}
        setPageData={setPageData}
        pageData={pageData}
        getList={getList}
        TeamsData={leads}
        listTitle={"Order List"}
        filter={filters}
        setFilter={setFilter}
      />
    </DashboardLayout>
  );
};

export default OrderListExpert;
