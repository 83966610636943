import React, { useState } from "react";
import { useSelector } from "react-redux";
import Icons from "../../../utils/Icons";
import ImageUrlList from "./ImageUrlList";

const FormFileUpload = ({
  uploadImage,
  imageRef,
  isScreenShot,
  setValue,
  getWatch,
  documentRef,
  data,
  STATUS,
  errors
}) => {
  const { isImageLoading } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const isManager = user?.userType === "MANAGER";
  const isClient = user?.userType === "CLIENT";
  const disable = data?.leadStatus === "PAID";
  const isOperator = user?.userType === "OPERATOR";
  const isAdminOrSuperAdmin = user?.userType === "ADMIN" || user?.userType === "SUPER_ADMIN";

  // State to manage drag over effect
  const [dragOver, setDragOver] = useState(false);

  // Handle drag over
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  // Handle drag leave
  const handleDragLeave = () => {
    setDragOver(false);
  };

  // Handle drop
  const handleDrop = (e, type) => {
    e.preventDefault();
    setDragOver(false);

    // Retrieve the dropped files
    const files = Array.from(e.dataTransfer.files);

    // Pass files to the uploadImage function
    if (type === "documents") {
      uploadImage({ target: { files } });
    } else if (type === "screenshots") {
      uploadImage({ target: { files } }, "screenshots");
    }
  };

  return (
    <div className="row">
      <div className={`col-sm-${isClient ? "12" : "6"}`}>
        <input
          type="file"
          onChange={uploadImage}
          ref={imageRef}
          className="display-none"
          multiple={true}
        />
        <div
          className={`input-file-drop ${dragOver ? "drag-over" : ""}`}
          onClick={() => {
            if (isOperator || isAdminOrSuperAdmin || isManager || !data) {
              imageRef.current?.click();
            }
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={(e) => handleDrop(e, "documents")}
        >
          <div className="parent-upload">
            <span>Upload Document</span>
            <span>
              {isImageLoading && !isScreenShot ? (
                <Icons title={"loader"} color="#0a376a" />
              ) : (
                <Icons title={"upload-file"} className={"upload-file"} />
              )}
            </span>
          </div>
        </div>
        <ImageUrlList
          onDelete={(image) => {
            let filterDocument = getWatch?.document?.filter(
              (item) => item !== image
            );
            setValue("document", filterDocument);
          }}
          items={getWatch?.document}
        />
      </div>

      {!isClient && (
        <div className="col-sm-6">
          <input
            type="file"
            multiple={true}
            onChange={(e) => uploadImage(e, "screenshots")}
            ref={documentRef}
            className="display-none"
          />
          <div
            className={`input-file-drop ${dragOver ? "drag-over" : ""}`}
            onClick={() => {
              if (isOperator || isAdminOrSuperAdmin || isManager) {
                documentRef.current?.click();
              }
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, "screenshots")}
          >
            <div className="parent-upload">
              <span className={errors[STATUS] && "text-danger"}>
                Payment Screenshot
                {getWatch[STATUS] === "PAID" && (
                  <b className="text-danger">*</b>
                )}
              </span>

              <span>
                {isImageLoading && isScreenShot ? (
                  <Icons title={"loader"} color="#0a376a" />
                ) : (
                  <Icons title={"upload-file"} className={"upload-file"} />
                )}
              </span>
            </div>
          </div>
          <ImageUrlList
            onDelete={(image) => {
              if (disable) {
                return false;
              }
              let filterDocument = getWatch?.screenshots?.filter(
                (item) => item !== image
              );
              setValue("screenshots", filterDocument);
            }}
            items={getWatch?.screenshots || []}
          />
        </div>
      )}
    </div>
  );
};

export default FormFileUpload;
