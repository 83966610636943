import React, { useEffect, useState } from "react";

const ShowMessage = ({ showMessage }) => {
  const [message, setShowMessage] = useState("");
  useEffect(() => {
    if (showMessage) {
      let tempString = showMessage?.replaceAll(
        "color: rgb(0, 0, 0)",
        "color: rgb(0, 0, 0)!important"
      );
      setShowMessage(tempString);
    }
  }, [showMessage]);
  return (
    <div className="show-message-query">
      {message && <div dangerouslySetInnerHTML={{ __html: message }}></div>}
    </div>
  );
};

export default ShowMessage;
