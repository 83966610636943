import React, { useEffect, useRef } from 'react';

const GoogleSignInButton = ({className,externalBtnRef, onLoginSuccess }) => {
  let loginBtnRef =useRef(null);
  loginBtnRef=externalBtnRef  || loginBtnRef;

  const initializeGoogleLogin = () => {
    if (window.google && window.google.accounts) {
      try {
        window.google.accounts.id.initialize({
          client_id: '584683567611-5tt0pqs208l539qvvbqol18vudmjl9qe.apps.googleusercontent.com',
          callback: onLoginSuccess,
        });

        window.google.accounts.id.renderButton(loginBtnRef.current, {
          theme: 'outline',
          size: 'large',
          width: '100%', // Set width to 100% for full responsiveness
        });

      } catch (error) {
        console.error('Error initializing Google login:', error);
      }
    } else {
      console.error('Google SDK is not available.');
    }
  };

  useEffect(() => {
    if (!window.google || !window.google.accounts) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleLogin;
      script.onerror = () => console.error('Failed to load the Google Identity Services SDK.');
      document.body.appendChild(script);
    } else {
      initializeGoogleLogin();
    }
  }, []);

  return <div className={className} ref={loginBtnRef} id="googleSignInButton" />;
};

export default GoogleSignInButton;
