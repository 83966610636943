import React, { useEffect, useState } from 'react'
import MobileAppPreview from './components/MobileAppPreview'
import EditorPanel from './components/EditorPanel'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getWelcomeScreenDataAction } from '../../services/action/common'

export default function AndroidApp() {
   const dispatch=useDispatch();
   const {androidWelcome} =useSelector((state)=>state.commonReducer);
   useEffect(()=>{
    dispatch(getWelcomeScreenDataAction())
   },[dispatch])

  const [editorData, setEditorData] = useState(null)

useEffect(()=>{
  if(androidWelcome){
    setEditorData(androidWelcome)
  }
},[androidWelcome])

  return (
    <DashboardLayout>
      <div className="flex h-screen bg-gray-100">
        {editorData && (
          <EditorPanel setEditorData={setEditorData} editorData={editorData} />
        )}
        {editorData && <MobileAppPreview editorData={editorData} />}
      </div>
    </DashboardLayout>
  );
}