import { Tooltip } from "@mui/material";
import LeadActionButtons from "../LeadActionButtons";
import {
  isMissedExpertDeadline,
  returnDateWithTime,
  truncateString,
} from "../../../utils/functions/functions";

export const ColumnLeadList = (
  afterSave,
  setShowExpertAssign,
  user,
  startChat,
  setClientId
) => {
  const isClient = user?.userType === "CLIENT";
  const isMissed = (row) => {
    return (
      isMissedExpertDeadline(row?.expertDeadline, row?.orderStatus) &&
      "text-danger"
    );
  };

  const columns = [
    {
      name: "",
      selector: (row) => row?.leadId, // Use a plain value for sorting
      cell: (row) => (
        <LeadActionButtons
          page="order"
          afterSave={afterSave}
          lead={row}
          setShowExpertAssign={setShowExpertAssign}
        />
      ),
      width: "59px",
      sortable: true,
    },
    {
      name: "Lead Id",
      selector: (row) => (isClient ? row?.orderId || row.leadId : row.leadId), // Return a plain value for sorting
      cell: (row) => (
        <td
          className={`pointer ${isMissed(row)}`}
          onClick={() => {
            startChat(row);
          }}
        >
          {isClient ? row?.orderId || row.leadId : row.leadId}
        </td>
      ),
      center: true,
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => row?.client?.name, // Plain value for sorting
      cell: (row) => (
        <td
          className={`pointer ${isMissed(row)}`}
          onClick={() => setClientId(row?.client)}
        >
          {row?.client?.name}
        </td>
      ),
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row?.subject, // Plain value for sorting
      cell: (row) => (
        <td
          className={`text-left pointer ${isMissed(row)}`}
          onClick={() => setClientId(row?.client)}
        >
          <Tooltip title={row?.subject}>
            {truncateString(row?.subject, 22)}
          </Tooltip>
        </td>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => row?.leadStatus, // Plain value for sorting
      cell: (row) => (
        <ul className={`chip-row show custom-chip text-center`}>
          <li className={`chip-child ${row.leadStatus}`}>
            {`${row.leadStatus}`}
          </li>
        </ul>
      ),
      center: true,
      sortable: true,
    },
    {
      name: "Client Deadline",
      selector: (row) => row?.clientDeadline, // Use the raw date for sorting
      cell: (row) => (
        <td className={`pointer ${isMissed(row)}`}>
          {returnDateWithTime(row?.clientDeadline)}
        </td>
      ),
      sortable: true,
      center: true,
    },
  ];

  return columns?.filter(Boolean);
};
