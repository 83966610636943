import React, { Fragment, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Buttons from "../../../../utils/buttons/Buttons";
import { Tooltip } from "@mui/material";
import CustomModal from "../../../../utils/modals/CustomModal";
import AssignExpert from "../assign-to-expert/AssignExpert";
const ExpertListOption = ({ lead, expertRequest, afterSave }) => {
  const [isAssignToExpert, setIsAssignToExpert] = useState(false);
  return (
    <Card className="h-100">
      <CustomModal
        title={"Assign To Expert"}
        open={isAssignToExpert}
        onClose={() => setIsAssignToExpert(false)}
        content={() => (
          <AssignExpert onClose={afterSave} openLead={{ ...lead, expert: isAssignToExpert }} />
        )}
      />
      <div className="row">
        <div className="col-12">
          <Table responsive className="text-nowrap">
            <thead className="table-light">
              <tr>
                <th>Sr No</th>
                <th>Expert Name</th>
                <th>Email</th>
                <th>Contact</th>
                <th>Comment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {expertRequest?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle pointer link">
                      {item?.expertId?.name}
                    </td>
                    <td className="align-middle pointer link">
                      {item?.expertId?.email}
                    </td>
                    <td
                      className="align-middle"
                      style={{ maxWidth: "150px", overflow: "hidden" }}
                    >
                      {item?.expertId?.countryCode} {item?.expertId?.number}
                    </td>
                    <td className="align-middle">
                      <Tooltip
                        title={
                          <Fragment>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item?.comment,
                              }}
                            ></span>
                          </Fragment>
                        }
                      >
                        <div
                          style={{
                            height: "18px",
                            overflow: "hidden",
                            width: "150px",
                          }}
                          dangerouslySetInnerHTML={{ __html: item?.comment }}
                        ></div>
                      </Tooltip>
                    </td>
                    <td
                      className="align-middle"
                      style={{ height: "18px", overflow: "hidden" }}
                    >
                      <Buttons
                        title={"Assign"}
                        onClick={() => setIsAssignToExpert(item?.expertId?._id)}
                        // onClick={() => onAssign(item?.expertId?._id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </Card>
  );
};

export default ExpertListOption;
