import { appActions } from "../../action";

const initialState = {};

export const managerReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${appActions.GET_ALL_USER_ACTION}_START`:
      if (action?.request?.data?.isEmptylist) {
        return { ...state, isLoadingUser: true, userList: [] };
      } else {
        return { ...state, isLoadingUser: true };
      }
    case `${appActions.GET_ALL_USER_ACTION}_SUCCESS`:
      if(action?.request?.firstTime){
        return { ...state, isLoadingUser: false, userList: action?.payload };
        }else{
          let tempData=[...state.userList?.data,...action?.payload?.data]
          return { ...state, isLoadingUser: false, userList: {...state.userList,data:tempData} };
        }
    case `${appActions.GET_ALL_USER_ACTION}_FAIL`:
      return { ...state, isLoadingUser: false, userList: [] };

    case `${appActions.CREATE_USER}_START`:
      return { ...state, isSaveLoading: true };
    case `${appActions.CREATE_USER}_SUCCESS`:
      return { ...state, isSaveLoading: false };
    case `${appActions.CREATE_USER}_FAIL`:
      return { ...state, isSaveLoading: false };

    case `${appActions.DELETE_USER_MANAGER}_START`:
      return { ...state, isDeleteUser: true };
    case `${appActions.DELETE_USER_MANAGER}_SUCCESS`:
      return { ...state, isDeleteUser: false };
    case `${appActions.DELETE_USER_MANAGER}_FAIL`:
      return { ...state, isDeleteUser: false };

    case `${appActions.GET_CLIENT_LIST_OPTION_MANAGER}_START`:
      return { ...state, isClientLoading: true };
    case `${appActions.GET_CLIENT_LIST_OPTION_MANAGER}_SUCCESS`:
      return {
        ...state,
        isClientLoading: false,
        clientOption: action?.payload,
      };
    case `${appActions.GET_CLIENT_LIST_OPTION_MANAGER}_FAIL`:
      return { ...state, isClientLoading: false };

    case `${appActions.GENERATE_LEAD_MANAGER}_START`:
      return { ...state, isSaveLoading: true };
    case `${appActions.GENERATE_LEAD_MANAGER}_SUCCESS`:
      return { ...state, isSaveLoading: false };
    case `${appActions.GENERATE_LEAD_MANAGER}_FAIL`:
      return { ...state, isSaveLoading: false };

    case `${appActions.UPDATE_LEAD_MANAGER}_START`:
      return { ...state, isUpdateLead: true };
    case `${appActions.UPDATE_LEAD_MANAGER}_SUCCESS`:
      return { ...state, isUpdateLead: false };
    case `${appActions.UPDATE_LEAD_MANAGER}_FAIL`:
      return { ...state, isUpdateLead: false };

    case `${appActions.GET_GENERATE_LEAD_ADMIN}_START`:
        return { ...state, isListLoading: true };
    case `${appActions.GET_GENERATE_LEAD_ADMIN}_SUCCESS`:
      if(action?.request?.firstTime){
      return { ...state, isListLoading: false, leads: action?.payload };
      }else{
        let tempData=[...state.leads?.data,...action?.payload?.data]
        return { ...state, isListLoading: false, leads: {...state.leads,data:tempData} };
      }
    case `${appActions.GET_GENERATE_LEAD_ADMIN}_FAIL`:
      return { ...state, isListLoading: false, leads: undefined };

    case `${appActions.GET_CHAT}_START`:
      return { ...state, isChatLoading: true };
    case `${appActions.GET_CHAT}_SUCCESS`:
      return { ...state, isChatLoading: false, chatList: action?.payload };
    case `${appActions.GET_CHAT}_FAIL`:
      return { ...state, isChatLoading: false };

    // case `${appActions.CHANGE_CHAT_VISIBITY}_START`:
    //   return { ...state, isUpdate: true };
    // case `${appActions.CHANGE_CHAT_VISIBITY}_SUCCESS`:
    //   const request=action?.request?.data;
    //   const tempList=[...state.chatList?.data?.sockets];
    //   const filterChatList=tempList?.filter((item)=>item?._id !==request?.messageId);
    //   return {
    //     ...state,
    //     isUpdate: false,
    //     chatList:{...state.chatList,sockets:filterChatList}
    //   };
    // case `${appActions.CHANGE_CHAT_VISIBITY}_FAIL`:
    //   return { ...state, isUpdate: false };

    default:
      return { ...state, isLoading: false };
  }
};
