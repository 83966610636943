import React, { useEffect, useState } from "react";
import OperatorListComponent from "../../components/manager/users/operators/OperatorListComponent";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAction } from "../../services/action/manager";
import DashboardLayout from "../../components/layouts/DashboardLayout";
const ExpertListSA = () => {
  const dispatch = useDispatch();
  const {userList}=useSelector((state)=>state.managerReducer);

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 15,
  });
  const [search, setSearch] = useState("");


  const getList=({ currentPage,firstTime=true,keyword=search })=>{
    dispatch(getAllUsersAction({userType:"super_admin", firstTime,data: { userType: "operator",page:currentPage,keyword } }));
  }
  useEffect(() => {
    getList({currentPage:1,firstTime:true})
  }, []);
  return (
    <DashboardLayout
    onSearch={(e)=>{
      setPageData({ page: 1 });
      setSearch(e);
      getList({currentPage:1,firstTime:true,keyword:e})}}
    >
      <OperatorListComponent getOperator={getList} TeamsData={userList} setPageData={setPageData} pageData={pageData}/>
    </DashboardLayout>
  );
};

export default ExpertListSA;
