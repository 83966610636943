import { v4 as uuid } from "uuid";

export const DashboardMenuManager = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    link: "/manager/dashboard",
  },
  {
    id: uuid(),
    title: "Users",
    icon: "layers",
    children: [
      { id: uuid(), link: "/manager/operators", name: "Operators" },
      { id: uuid(), link: "/manager/experts", name: "Experts" },
      { id: uuid(), link: "/manager/clients", name: "Clients" },
      { id: uuid(), link: "/manager/marketings", name: "Marketings" },

    ],
  },
  {
    id: uuid(),
    title: "Leads & Order",
    icon: "lock",
    children: [
      { id: uuid(), link: "/manager/leads", name: "Leads" },
      { id: uuid(), link: "/manager/orders", name: "Orders" },
      { id: uuid(), link: "/manager/query", name: "Queries" },
    ],
  },
  {
    id: uuid(),
    title: "Live Users",
    icon: "home",
    link: "/manager/live-users",
  },
  {
    id: uuid(),
    title: "Reports",
    icon: "report",
    children: [
      { id: uuid(), link: "/manager/report/order", name: "Orders" },
      { id: uuid(), link: "/manager/report/user", name: "Users" },
      { id: uuid(), link: "/manager/report/revenue", name: "Revenue" },
    ],
  },
  {
    id: uuid(),
    title: "Android App panel",
    icon: "android",
    link: "/manager/android",
    children: [
      {id: uuid(), link: "/manager/androidpanel", name: "Home Screen"},
    ]
  },
];
export const DashboardMenuExpert = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    link: "/expert/dashboard",
  },
  {
    id: uuid(),
    title: "Orders",
    icon: "home",
    link: "/expert/orders",
  },
  {
    id: uuid(),
    title: "Available Orders",
    icon: "home",
    link: "/expert/open-lead",
  },
];

export const DashboardMenuOperator = (user) => {
  const tempSidebar = [
    {
      id: uuid(),
      title: "Dashboard",
      icon: "home",
      link: "/operator/dashboard",
    },
    {
      id: uuid(),
      title: "Leads",
      icon: "home",
      link: "/operator/leads",
    },
    {
      id: uuid(),
      title: "Orders",
      icon: "home",
      link: "/operator/orders",
    },

    {
      id: uuid(),
      title: "Reports",
      icon: "report",
      children: [
        
        { id: uuid(), link: "/operator/report/order", name: "Orders" },
      ],
    },
    {
      id: uuid(),
      title: "Queries",
      icon: "queries",
      link:"/operator/query "
    },
  ];
  if (user?.departMent === "SALES") {
    tempSidebar.push({
      id: uuid(),
      title: "Live Users",
      icon: "track",
      link: "/operator/live-users",
    });
  }
  return tempSidebar;
};
export const DashboardMenuClient = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    link: "/client/dashboard",
  },
  {
    id: uuid(),
    title: "Orders",
    icon: "home",
    link: "/client/leads",
  },
  // {
  // 	id: uuid(),
  // 	title: 'Orders',
  // 	icon: 'home',
  // 	link: '/client/orders'
  // }
];

export const SidebarOptionSuperAdmin = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    link: "/super-admin/dashboard",
  },
  {
    id: uuid(),
    title: "Users",
    icon: "layers",
    children: [
      { id: uuid(), link: "/super-admin/operators", name: "Operators" },
      { id: uuid(), link: "/super-admin/experts", name: "Experts" },
      { id: uuid(), link: "/super-admin/clients", name: "Clients" },
      { id: uuid(), link: "/super-admin/managers", name: "Managers" },
      { id: uuid(), link: "/super-admin/admins", name: "Admins" },
      { id: uuid(), link: "/super-admin/marketings", name: "Marketings" },
    ],
  },
  {
    id: uuid(),
    title: "Leads & Order",
    icon: "lock",
    children: [
      { id: uuid(), link: "/super-admin/leads", name: "Leads" },
      { id: uuid(), link: "/super-admin/orders", name: "Orders" },
      { id: uuid(), link: "/super-admin/query", name: "Queries" },
    ],
  },
  {
    id: uuid(),
    title: "Live Users",
    icon: "track",
    link: "/super-admin/live-users",
  },
  {
    id: uuid(),
    title: "Reports",
    icon: "report",
    children: [
      { id: uuid(), link: "/super-admin/report/order", name: "Orders" },
      { id: uuid(), link: "/super-admin/report/user", name: "Users" },
      { id: uuid(), link: "/super-admin/report/revenue", name: "Revenue" },
    ],
  },
  {
    id: uuid(),
    title: "Android App panel",
    icon: "android",
    link: "/super-admin/android",
    children: [
      { id: uuid(), link: "/super-admin/androidpanel", name: "Home Screen" },
    ]
  },
];

export const SidebarOptionAdmin = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    link: "/admin/dashboard",
  },
  {
    id: uuid(),
    title: "Users",
    icon: "layers",
    children: [
      { id: uuid(), link: "/admin/operators", name: "Operators" },
      { id: uuid(), link: "/admin/experts", name: "Experts" },
      { id: uuid(), link: "/admin/clients", name: "Clients" },
      { id: uuid(), link: "/admin/managers", name: "Managers" },
      { id: uuid(), link: "/admin/marketings", name: "Marketings" },

    ],
  },
  {
    id: uuid(),
    title: "Leads & Order",
    icon: "lock",
    children: [
      { id: uuid(), link: "/admin/leads", name: "Leads" },
      { id: uuid(), link: "/admin/orders", name: "Orders" },
      { id: uuid(), link: "/admin/query", name: "Queries" },
    ],
  },
  {
    id: uuid(),
    title: "Live Users",
    icon: "track",
    link: "/admin/live-users",
  },
  {
    id: uuid(),
    title: "Reports",
    icon: "report",
    children: [
      { id: uuid(), link: "/admin/report/order", name: "Orders" },
      { id: uuid(), link: "/admin/report/user", name: "Users" },
      { id: uuid(), link: "/admin/report/revenue", name: "Revenue" },
    ],
  },
  {
    id: uuid(),
    title: "Android App panel",
    icon: "android",
    link: "/admin/android",
    children: [
      { id: uuid(), link: "/admin/androidpanel", name: "Home Screen" },
    ]
  },
];

export const SidebarOptionMarketing = [
  {
    id: uuid(),
    title: "Live Users",
    icon: "track",
    link: "/marketings/live-users",
  },
];
