import React, { useEffect } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ReportCardOrders } from "../utils/ProjectsStatsData";
import { useDispatch, useSelector } from "react-redux";
import StatRightTopIcon from "../utils/StatRightTopIcon";
import OrderHistoryGraph from "../utils/graph/OrderHistoryGraph";
import { getReportsAction } from "../../services/action/common";
import SelectDate from "./components/SelectDate";

const OrderReport = () => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { reports } = useSelector((state) => state.commonReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(
        getReportsAction({
          userType: user?.userType?.toLowerCase(),
          data: { reportType: "ORDERS" },
        })
      );
    }
  }, [user, dispatch]);

  return (
    <DashboardLayout>
      <div
        className=" pt-10 pb-21"
        style={{ backgroundColor: "#00234a" }}
      ></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            {/* Page header */}
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0  text-white">Orderwise Reports</h3>
                </div>
                <SelectDate/>
              </div>
            </div>
          </Col>
          {ReportCardOrders(reports?.current).map((item, index) => {
            return (
              <Col xl={3} lg={6} md={12} xs={12} className="mt-6" key={index}>
                <StatRightTopIcon info={item} />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            {reports?.reports?.length && (
              <OrderHistoryGraph data={reports?.reports} />
            )}
          </Col>
        </Row>
      </Container>
    </DashboardLayout>
  );
};

export default OrderReport;
