import { CircularProgress } from "@mui/material";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  AttachMoney,
  Call,
  Clear,
  Google,
  GridViewRounded,
  Refresh,
  StartSharp,
  ViewListRounded,
} from "@mui/icons-material";
import React from "react";
import { Edit3, File, Filter, Minus, Upload } from "react-feather";
import { SendArrowUpFill } from "react-bootstrap-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StreamIcon from "@mui/icons-material/Stream";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
const Icons = ({
  title,
  style = {},
  color = "white",
  size = 21,
  className,
  onClick,
}) => {
  switch (title) {
    case "loader":
      return (
        <CircularProgress style={{ color }} size={size} className={className} />
      );
    case "cross":
      return (
        <Clear style={{ color, ...style }} size={size} className={className} />
      );
    case "upload-file":
      return <Upload size={size} className={className} />;
    case "filter":
      return <Filter size={size} className={className} />;
    case "send-icon":
      return <SendArrowUpFill color={color} size={size} />;
    case "minus":
      return <Minus style={{ color, ...style }} size={size} />;
    case "start":
      return <StartSharp style={{ color }} size={size} />;
    case "edit":
      return <Edit3 style={{ color }} size={size} />;
    case "payment":
      return <AttachMoney onClick={onClick} style={{ color }} size={size} />;
    case "share-file":
      return <File onClick={onClick} style={{ color }} size={size} />;
    case "three-dots":
      return (
        <MoreVertIcon
          style={{ color }}
          onClick={onClick}
          size={size}
          className={className}
        />
      );
    case "maxmize":
      return (
        <StreamIcon
          onClick={onClick}
          style={{ color }}
          size={size}
          className={className}
        />
      );
    case "emoji":
      return (
        <SentimentSatisfiedAltIcon
          onClick={onClick}
          style={{ ...style, color }}
          size={size}
          className={className}
        />
      );
    case "file":
      return (
        <AttachFileIcon
          onClick={onClick}
          style={{ color }}
          size={size}
          className={className}
        />
      );
    case "mic":
      return (
        <KeyboardVoiceIcon
          onClick={onClick}
          style={{ color }}
          size={size}
          className={className}
        />
      );
    case "down-arrow":
      return (
        <ArrowDropDownOutlined
          onClick={onClick}
          style={{ color }}
          size={size}
          className={className}
        />
      );
    case "arrow-down":
      return (
        <ArrowDropUpOutlined
          onClick={onClick}
          style={{ color }}
          size={size}
          className={className}
        />
      );

    case "arrow-up":
      return (
        <ArrowDropDownOutlined
          onClick={onClick}
          style={{ color }}
          size={size}
          className={className}
        />
      );
    case "call":
      return <Call style={{ color }} size={size} className={className} />;

    case "date-filter":
      return (
        <ManageHistoryIcon
          style={{ color }}
          size={size}
          className={className}
        />
      );
    case "track":
      return (
        <StackedLineChartIcon
          style={{ color }}
          size={size}
          className={className}
        />
      );
    case "refresh":
      return <Refresh style={{ color }} size={size} className={className} />;
    case "grid-view":
      return (
        <GridViewRounded style={{ color }} size={size} className={className} />
      );
    case "list-view":
      return (
        <ViewListRounded style={{ color }} size={size} className={className} />
      );
      case "google":
      return (
        <Google style={{ color }} size={size} className={className} />
      );

    default:
      return "";
  }
};

export default Icons;
