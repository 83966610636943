import { Card } from "react-bootstrap";
import React, { Fragment, useEffect, useRef, useState } from "react";
import socket from "../socket/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLiveUserListAction,
  startOpenChatAction,
} from "../../services/action/common";
import Icons from "../utils/Icons";
import { Tooltip } from "@mui/material";
import LiverUserFilterChips from "./components/LiverUserFilterChips";
import LiveUserSelectWebsite from "./components/LiveUserSelectWebsite";
import DateRangeFilter from "./components/DateRangeFilter";
import CustomDataTable from "../utils/data-tables/CustomDataTable";
import { ColumnLiveUser } from "./components/ColumnLiveUser";
const LiveUserTrack = ({pageData,setPageData,getAllLiveUser,setFilter,filter}) => {
  const [currentUserList, setCurrentUserList] = useState({
    paginations: "",
    data: [],
  });
  const { user } = useSelector((state) => state.authenticationReducer);

  const { allLiveUser } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const [existUser, setExistUser] = useState(false);
 
  useEffect(() => {
    if (existUser) {
      let existUserIndex = currentUserList?.data?.findIndex(
        (item) => item?._id === existUser?._id
      );
      const tempData = [...currentUserList?.data];
      if (existUserIndex != -1) {
        tempData[existUserIndex] = existUser;
        setCurrentUserList((prev) => ({ ...prev, data: tempData }));
        setExistUser(false);
      } else {
        setCurrentUserList((prev) => ({
          ...prev,
          data: [existUser, ...prev.data],
        }));
        setExistUser(false);
      }
    }
  }, [existUser]);

  const audioRef = useRef();
  const startChat = (currentuser) => {
    dispatch(startOpenChatAction(currentuser));
  };

  useEffect(() => {
    socket.on("all_live_user", (currentData) => {
      if (currentData?.existUser) {
        setExistUser(currentData);
      } else {
        setCurrentUserList((prev) => ({
          ...prev,
          data: [...prev?.data, currentData],
        }));
        startChat(currentData);
        if (audioRef.current) {
          const playPromise = audioRef.current.play();
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.log("Playback prevented:", error);
            });
          }
        }
      }
    });
    return () => {
      socket.off("all_live_user");
    };
  }, []);



  useEffect(() => {
    if (allLiveUser) {
      setCurrentUserList(allLiveUser);
    }
  }, [allLiveUser]);

  const isMarketing = user?.userType === "MARKETING";
  const isManagerOrAdmin =
    user?.userType === "ADMIN" ||
    user?.userType === "MANAGER" ||
    user?.userType === "SUPER_ADMIN";

  const fetchMoreData = () => {
    setPageData({ page: pageData?.page + 1 });
    getAllLiveUser({ page: pageData?.page + 1, firstTime: false });
  };

  const downLoadBtnRef = useRef();

  return (
    <>
      <Card className="h-100 live-user-tracker">
        <audio ref={audioRef} src={"/assets/first-time.mp3"} />
        <Card.Header className="bg-white py-4">
          <h4 className="mb-0">
            <Tooltip title={"Graph View"}>
              <span className="pointer">
                <Icons title={"grid-view"} color="black" className={"mr-2"} />
              </span>
            </Tooltip>
            <div className="live-user-counter">
              <LiveUserSelectWebsite
                filter={filter}
                getAllLiveUser={getAllLiveUser}
                setFilter={setFilter}
                setPageData={setPageData}
              />
              <LiverUserFilterChips
                label={`Total User (${
                  currentUserList?.paginations?.total || 0
                })`}
                backgroundColor="blue"
                className="ml-3 mt-2"
              />
              <LiverUserFilterChips
                label={`Online Users (${
                  currentUserList?.paginations?.onlineUsers || 0
                })`}
                className="ml-3 mt-2"
                isFilled={filter?.isOnline}
                onClick={() => {
                  setFilter((prev) => ({ ...prev, isOnline: !prev?.isOnline }));
                  setPageData({ page: 1 });
                  getAllLiveUser({
                    tempIsOnline: !filter?.isOnline,
                    firstTime: true,
                    page: 1,
                  });
                }}
              />
              <LiverUserFilterChips
                label={`Today's User (${
                  currentUserList?.paginations?.todayUserCount || 0
                })`}
                backgroundColor="#0a376a"
                className="ml-3 mt-2"
                isFilled={filter?.onlyTodayUser}
                onClick={() => {
                  setFilter((prev) => ({
                    ...prev,
                    onlyTodayUser: !prev?.onlyTodayUser,
                  }));
                  setPageData({ page: 1 });
                  getAllLiveUser({
                    page: 1,
                    tempOnlyTodayUser: !filter?.onlyTodayUser,
                    firstTime: true,
                  });
                }}
              />
              <DateRangeFilter
                filter={filter}
                setFilter={setFilter}
                getAllLiveUser={getAllLiveUser}
                currentUserList={currentUserList}
              />

              {isManagerOrAdmin && (
                <LiverUserFilterChips
                  label={`Export in CSV`}
                  backgroundColor="gray"
                  className="ml-3 mt-2"
                  onClick={() => downLoadBtnRef?.current?.click()}
                />
              )}
            </div>
          </h4>
        </Card.Header>
        <CustomDataTable
          data={currentUserList?.data}
          columns={ColumnLiveUser(startChat, isMarketing)}
          isInfinitScroll={true}
          fetchMoreData={fetchMoreData}
          downLoadBtnRef={downLoadBtnRef}
        />
      </Card>
    </>
  );
};

export default LiveUserTrack;
