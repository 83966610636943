// import node module libraries
import React, { useState } from "react";
import { Card } from "react-bootstrap";

import Buttons from "../../../utils/buttons/Buttons";
import CustomModal from "../../../utils/modals/CustomModal";
import FormEditClient from "./components/FormEditClient";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserActionManager,
  updateUserActionManager,
} from "../../../../services/action/manager";
import DeleteConfirmation from "../../../utils/dialoge/DeleteConfirmation";
import FullScreenModal from "../../../utils/modals/FullScreenModal";
import PersonalCard from "../../../card/PersonalCard";
import CustomDataTable from "../../../utils/data-tables/CustomDataTable";
import { ClientListColumn } from "./ClientListColumn";

const ClientListCompoent = ({
  getList,
  TeamsData,
  listTitle = "Client List",
  listType = "CLIENT",
  setPageData,
  pageData,
}) => {
  const { isDeleteUser } = useSelector(
    (state) => state.managerReducer
  );
  const { user } = useSelector((state) => state.authenticationReducer);
  const dispatch = useDispatch();
  const [operatorDetail, setOperatorDetail] = useState({
    modal: false,
    preview: false,
    edit: false,
    data: "",
  });

  const [deleteId, setDeleteId] = useState("");


  const afterSave = () => {
    getList({ currentPage:1, isEmptylist: false });
    setOperatorDetail({});
  };

  const deleteFinal = () => {
    const userType = user?.userType?.toLowerCase();
    dispatch(
      deleteUserActionManager({
        userType,
        data: deleteId,
        cb: () => {
          setDeleteId("");
          getList({ currentPage:1, isEmptylist: false });
          setOperatorDetail({});
        },
      })
    );
  };

  const updateStatus = (data) => {
    const userType = user?.userType?.toLowerCase();
    dispatch(
      updateUserActionManager({
        userType,
        data: {
          _id: data?._id,
          status: data?.status === "ACTIVE" ? "NOT ACTIVE" : "ACTIVE",
        },
        cb: () => getList({ currentPage:1, isEmptylist: false }),
      })
    );
  };

  const fetchMoreData = () => {
    setPageData({ page: pageData?.page + 1 });
    getList({ currentPage: pageData?.page + 1, firstTime: false });
  };

  return (
    <Card className="h-100">
      <DeleteConfirmation
        open={deleteId}
        isDeleteLoading={isDeleteUser}
        onClose={() => setDeleteId("")}
        onConfirm={deleteFinal}
      />
      <CustomModal
        title={listType === "MARKETING" ? "Add Maketing Team" : "Add Client"}
        bgContent="dark-bg"
        open={operatorDetail.modal}
        onClose={() => setOperatorDetail({})}
        content={() => (
          <FormEditClient
            listType={listType}
            data={operatorDetail?.data}
            close={afterSave}
          />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={operatorDetail.preview}
        onClose={() => setOperatorDetail({})}
        content={() => (
          <PersonalCard
            onDeleteClick={(data) => setDeleteId(data)}
            onEditClick={(data) =>
              setOperatorDetail({
                edit: true,
                modal: true,
                preview: false,
                data,
              })
            }
            data={operatorDetail?.data}
            close={() => setOperatorDetail({})}
          />
        )}
      />

      <Card.Header className="bg-white py-4">
        <h4 className="mb-0">
          {listTitle}{" "}
          <Buttons
            onClick={() => setOperatorDetail({ modal: true, edit: false })}
            title={
              listType === "MARKETING" ? "Add Marketing Team" : "Add Client"
            }
            icon="plus"
          />{" "}
        </h4>
      </Card.Header>
      <CustomDataTable
        columns={ClientListColumn(
          setOperatorDetail,
          updateStatus
        )}
        isInfinitScroll={true}
        fetchMoreData={fetchMoreData}
        data={TeamsData?.data}
      />
    </Card>
  );
};

export default ClientListCompoent;
