import { Fragment, useRef, useState } from "react";
import CustomModal from "../../../utils/modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../../../services/action/common";
import { FormGroup } from "@mui/material";
import Icons from "../../../utils/Icons";
import ImageUrlList from "../../../manager/leads/components/ImageUrlList";
import Buttons from "../../../utils/buttons/Buttons";
import EditorText from "../../../utils/text-editor/EditorText";
import { removeUnnecessaryTags } from "../../../utils/functions/functions";
import { updateLeadsActionManager } from "../../../../services/action/manager";
import moment from "moment-timezone";
import { Toaster } from "../../../Toaster";

const ReopenForm = ({ reopen, setReopen, sendMessage }) => {
  const dispatch = useDispatch();
  const { leadDetail } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [messageContent, setMesageContent] = useState("");
  const fileRef = useRef();
  const [imageUrls, setImageUrls] = useState([]);
  const [reopenDeadline, setReopenDeadline] = useState(null);

  const { isImageLoading } = useSelector((state) => state.commonReducer);

  const uploadImage = (e) => {
    const callBackImage = (res) => {
      let tempData = [...imageUrls];
      setImageUrls([...tempData, ...res?.imageUrls]);
    };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  const convertToIST = (dateTime) => {
    return moment.tz(dateTime, "Asia/Kolkata").format("YYYY-MM-DDTHH:mm");
  };

  const onSubmit = () => {
    if(reopenDeadline==null || !reopenDeadline){
     Toaster.error("Reopen Deadline is required")
     return false;
    }
    if(imageUrls?.length<1){
        Toaster.error("Files is required")
        return false;
       }
    let messageFile = "";
    imageUrls?.forEach((item) => {
      messageFile += `<a target='_blank' href=${item}><i class="fa fa-file" aria-hidden="true"></i></a>`;
    });

    const message = `<div class="delivery-chat-box re-open-file-download"><b>Order Reopen</b> </br> <b style="color="blue">Reason:</b>${removeUnnecessaryTags(messageContent)} <p class="file-list">${messageFile} </p></div>`;
    sendMessage(message, false, ["ALL"]);
    setReopen({});
    const payload = {
      orderStatus: "REOPEN",
      _id: leadDetail?._id,
      reopenDeadline: reopenDeadline ? moment.tz(reopenDeadline, "Asia/Kolkata").toISOString() : null,
      reason: removeUnnecessaryTags(messageContent),
    };
    dispatch(updateLeadsActionManager({ userType: user?.userType, data: payload }));
  };

  const Content = () => {
    return (
      <FormGroup>
        <EditorText setValue={setMesageContent} value={messageContent} />
        <div
          className="delivery-box-input-file mt-2"
          onClick={() => fileRef.current?.click()}
        >
          <p className="m-0 text-center">
            Upload File<span className="text-danger">*</span>
          </p>
          {isImageLoading && (
            <p className="m-0 text-center">
              <Icons title={"loader"} color="#0a376a" />
            </p>
          )}
        </div>
        <input
          type="file"
          onChange={uploadImage}
          className="display-none"
          ref={fileRef}
          multiple={true}
        />
        {!!imageUrls?.length && (
          <ImageUrlList
            items={imageUrls}
            onDelete={(image) => {
              let filterDocument = imageUrls?.filter((item) => item !== image);
              setImageUrls(filterDocument);
            }}
          />
        )}
        <div className="mb-4">
          <label>
            Reopen Deadline<span className="text-danger">*</span>
          </label>
          <input
            min={convertToIST(new Date().toISOString())}
            value={
              reopenDeadline
                ? convertToIST(new Date(reopenDeadline))
                : reopenDeadline
            }
            onChange={(e) => setReopenDeadline(e.target.value)}
            type="datetime-local"
            className="form-control"
          />
        </div>
        <Buttons title={"Submit"} onClick={onSubmit} />
      </FormGroup>
    );
  };

  return (
    <Fragment>
      <CustomModal
        open={reopen?.modal}
        title={"Write reason for Reopen order"}
        zIndex={10000}
        size="md"
        content={Content}
        onClose={() => setReopen({})}
      />
    </Fragment>
  );
};

export default ReopenForm;
