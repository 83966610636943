import React, { useEffect, useState } from "react";
import LeadListComponent from "../../components/manager/leads/LeadListComponent";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsAction } from "../../services/action/manager";
const LeadListClient = () => {
  const dispatch = useDispatch();
  const { leads } = useSelector((state) => state.managerReducer);
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 15,
  });
  const [filters, setFilter] = useState(["ALL_LEADS"]);

  const getList = ({
    currentPage = pageData?.page,
    firstTime = false,
    isEmptylist = true,
    search = "",
    filter = filters,
    sortConfig,
    dateFilter,
  }) => {
    dispatch(
      getLeadsAction({
        userType: "client",
        firstTime,
        data: {
          sortConfig,
          search,
          page: currentPage,
          isEmptylist,
          dateFilter,
          leadStatus: filter,
        },
      })
    );
  };
  
  useEffect(() => {
    getList({ currentPage: 1, firstTime: true });
  }, []);
  return (
    <DashboardLayout onSearch={(value)=>getList({search:value})}>
      <LeadListComponent isClientView={true} setFilter={setFilter} filter={filters} pageData={pageData} setPageData={setPageData} getList={getList} TeamsData={leads} addButtonTitle="Create new order" listTitle="Order List"/>
    </DashboardLayout>
  );
};

export default LeadListClient;
