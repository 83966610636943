const { appActions } = require("..");

export const getAllUsersAction=(payload)=>({
  type:appActions.GET_ALL_USER_ACTION,
  payload
})

export const createUserActionManager=(payload)=>({
  type:appActions.CREATE_USER,
  payload
})

export const deleteUserActionManager=(payload)=>({
  type:appActions.DELETE_USER_MANAGER,
  payload
})
export const updateUserActionManager=(payload)=>({
  type:appActions.UPDATE_USER_MANAGER,
  payload
})
export const getClientListOptionAction=(payload)=>({
  type:appActions.GET_CLIENT_LIST_OPTION_MANAGER,
  payload
})

export const generateLeadActionManager=(payload)=>({
  type:appActions.GENERATE_LEAD_MANAGER,
  payload
})
export const getLeadsAction=(payload)=>({
  type:appActions.GET_GENERATE_LEAD_ADMIN,
  payload
})

export const updateLeadsActionManager=(payload)=>({
  type:appActions.UPDATE_LEAD_MANAGER,
  payload
})

export const changeChatVisiblityAction=(payload)=>({
  type:appActions.CHANGE_CHAT_VISIBITY,
  payload
})

export const orderDeliveredAction=(payload)=>({
  type:appActions.ORDER_DELIVERED_ACTION,
  payload
})

export const sendBalancedAmountAction=(payload)=>({
  type:appActions.SEND_BALANCED_AMOUNT_TO_CLIENT,
  payload
})

export const unAssignedLeadAction=(payload)=>({
  type:appActions.UNASSIGNED_LEAD,
  payload
})