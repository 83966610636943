import { Backdrop } from "@mui/material";
import React, { useState } from "react";
import Icons from "../../utils/Icons";
import Buttons from "../../utils/buttons/Buttons";
import { currancyList, paymentType } from "../../utils/TeamsData";
import { useDispatch, useSelector } from "react-redux";
import { generatePaymentLinkAction, sendPaymentLinkOnWhatsappAction } from "../../../services/action/common";

const PaymentButton = ({ sendMessage,leadId }) => {
  const [openGeneratePaymentLink, setGeneratePaymentLink] = useState(false);
  const { user } = useSelector((state) => state.authenticationReducer);

  const dispatch = useDispatch();
  const userType = user?.userType?.toLowerCase();
  const [isGenerating, setIsGenerating] = useState(false);

  const [obj, setObj] = useState({
    currency: "",
    amount: 0,
  });

  const generateLink = () => {
    const callBack = (res) => {
      sendMessage(
        `<a data="ONLY_FOR_CLIENT PAYAMENT_LINK" style='color:#49e48c'  target='_blank' href=${res?.url}>Click here to pay</a>`
      );
      setIsGenerating(false);
      setGeneratePaymentLink(false);
      setObj({
        currency: "",
        amount: 0,
      });

      const paymentObj={
        leadId:leadId,
        link:res?.url,
      }
      dispatch(sendPaymentLinkOnWhatsappAction({userType,data:paymentObj}))
    };
    setIsGenerating(true);
    dispatch(generatePaymentLinkAction({ userType, data: obj, cb: callBack }));
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      setGeneratePaymentLink(false);
    }
  };
  return (userType=="operator" || userType=="manager" || userType=="admin" || userType=="super_admin") && (
    <>
      <span className="pointer mt-1" aria-label="More Options">
        <Backdrop
          onClick={handleBackdropClick}
          open={openGeneratePaymentLink}
          style={{ zIndex: 1, backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        >
          <div
            className="generate-payment-link"
            role="dialog"
            aria-modal="true"
          >
            <div className="row">
              <div className="col-sm-3">
                <select
                  className="form-control input"
                  aria-label="Select Currency"
                  value={obj.currency}
                  onChange={(e) => setObj({ ...obj, currency: e.target.value })}
                >
                  {currancyList?.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3">
                <select
                  className="form-control input"
                  aria-label="Payment Type"
                  value={obj.paymentType}
                  onChange={(e) => setObj({ ...obj, paymentType: e.target.value })}
                >
                  {paymentType?.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3">
                <input
                  type="number"
                  placeholder="Enter Amount"
                  className="form-control input"
                  aria-label="Enter Amount"
                  value={obj.amount}
                  onChange={(e) => setObj({ ...obj, amount: e.target.value })}
                />
              </div>
              <div className="col-sm-3 icon-btn">
                <Buttons
                  iconClass="margin-0"
                  isLoading={isGenerating}
                  onClick={generateLink}
                  size="medium"
                  icon="setting"
                  title="Generate"
                />
              </div>
            </div>
          </div>
        </Backdrop>
        <Icons
          onClick={() => {
            setObj({});
            setGeneratePaymentLink(!openGeneratePaymentLink);
          }}
          title="payment"
          color="#fff"
          size={26}
        />
      </span>
    </>
  );
};
export default PaymentButton;
