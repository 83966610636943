import axios from "axios";
import api from "./apiInstance";

export const commonApi = {
  LOGIN: ({ data }) => api.post("/login", data),
  SIGNUP:({data})=>api.post("/register",data),
  GET_USER:()=>api.get("/user"),
  GET_DASHBOARD:({userType})=>api.get(`/${userType}/get-dashboard`),
  GET_EXPERT_LIST_OPTION:({userType})=>api.get(`/${userType}/expert-list-option`),
  GET_NOTIFICATION_LIST:({userType})=>api.get(`/${userType}/get-notification`),
  UPDATE_NOTIFICATION_STATUS:({userType,data})=>api.post(`/${userType}/update-notification-status`,data),
  GENERATE_PAYMENT_LINK:({userType,data})=>api.post(`/${userType}/create-payment-link`,data),
  GET_COUNTRY_LIST:()=>axios.get("https://restcountries.com/v3.1/all"),
  UPLOAD_AUDIO:({data})=>api.post("/upload-file",data),
  RESET_PASSWORD:({ data }) => api.post("/reset-password", data),
  UPDATE_PASSWORD:({ data }) => api.put("/update-password", data),
  SENT_PAYMENT_LINK_WHATSAPP:({userType,data})=>api.post(`/${userType}/send-payment-link-to-client`,data),
  CHANGE_CALL_HIPPO_PERMISSION:({userType,data})=>api.post(`/${userType}/switch-call-hippo-permission`,data),
  GET_OPERATOR_MANAGER_LIST_ACTION:({userType})=>api.get(`/${userType}/operator-or-manager-list-option`),
  REQUEST_FOR_ASSIGN_LEAD:({userType,data})=>api.put(`/${userType}/request-assign-lead`,data),
  GET_ALL_LIVE_USER_LIST:({userType,filter})=>api.post(`/${userType}/get-all-live-user`,filter),
  GET_OPEN_USER_CHAT_LIST:({data})=>api.post(`/get-open-user-chat`,data),
  GET_OPEN_NOTIFICATION:({data})=>api.post(`/get-open/notication`,data),
  GET_REPORTS:({userType,data})=>api.post(`/${userType}/get-report`,data),
  GET_LEAD_DETAILS:({userType,data})=>api.post(`/${userType}/get-lead-detail`,data),
  GET_EXPERT_QUERIES:({userType,data,page})=>api.post(`/${userType}/get-queries?page=${page}`,data),
  UPDATE_QUERY_STATUS:({userType,data})=>api.put(`/${userType}/update-query-status`,data),
  UPDATE_CURRENCY:({userType,data})=>api.put(`/${userType}/update-currenccies`,data),
  GET_CURRENCY_LIST:({userType,data})=>api.get(`/${userType}/get-currency`,data),
  UNASSIGNED_LEAD:({userType,data})=>api.post(`/${userType}/remove-assign-lead`,data),
  VERIFY_GOOGLE_TOKEN:({data})=>api.post(`/google/verify-email`,data),
  GET_ORDER_ACTIVITY:({userType,data})=>api.get(`/${userType}/get-order-activity/${data?.leadId}`),
  SET_PENALTY:({userType,data})=>api.post(`/${userType}/set-penalty`,data),
  GET_WELCOME_SCREEN_DATA:()=>api.get("/get-welcome-screen"),
  ON_UPDATE_ANDROID_DESIGN:({userType,data})=>api.put(`/${userType}/update-mobile-screen`,data),
}