
import {  ChevronRight,  ChevronDown } from 'lucide-react'
const EditorSection = ({ title, children, isActive, onToggle }) => (
    <div className="border rounded-lg overflow-hidden mb-4">
      <div
        className="flex items-center justify-between p-4 bg-gray-100 cursor-pointer"
        onClick={onToggle}
      >
        <h2 className="text-lg font-semibold">{title}</h2>
        {isActive ? <ChevronDown className="h-5 w-5" /> : <ChevronRight className="h-5 w-5" />}
      </div>
      {isActive && <div className="p-4 bg-white">{children}</div>}
    </div>
  )

  export default EditorSection;