
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
export const GoogleButtonBackground = styled(motion.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#0a376a',
  zIndex: 1,
});

export const GoogleButtonContent = styled(motion.div)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  color: '#ffffff',
  fontWeight: 'bold',
  zIndex: 2,
});


export const GoogleButtonWrapper = styled(motion.div)({
  position: 'relative',
  width: '100%',
  height: '50px',
  overflow: 'hidden',
  borderRadius: '4px',
  cursor: 'pointer',
});

export const GoogleButtonWrapperLogin = styled(motion.div)({
  position: 'relative',
  width: '100%',
  height: '50px',
  overflow: 'hidden',
  cursor: 'pointer',
  height:'53px',
  marginTop:"6px",
  borderRadius: '30px',
});

export const handleGoogleButtonHover = (buttonControls) => {
  buttonControls.start({
    scale: 1.05,
    transition: { duration: 0.2 },
  });
};

export const handleGoogleButtonLeave = (buttonControls) => {
  buttonControls.start({
    scale: 1,
    transition: { duration: 0.2 },
  });
};
