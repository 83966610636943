import React, { useEffect, useState } from "react";
import OrderListComponent from "../../components/manager/leads/OrderListComponent";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsAction } from "../../services/action/manager";

const OpenLeadExpert = () => {
  const dispatch = useDispatch();
  const { leads } = useSelector((state) => state.managerReducer);
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 15,
  });

  const getList = ({
    currentPage = pageData?.page,
    firstTime = false,
    isEmptylist = true,
    search = "",
    filter = ["OPEN"],
  }) => {
    dispatch(
      getLeadsAction({
        userType: "expert",
        firstTime,
        data: {
          search,
          page: currentPage,
          isEmptylist,
          orderStatus: filter,
          allOpen: "OPEN",
          status: filter,
        },
      })
    );
  };

  useEffect(() => {
    getList({ currentPage: 1, firstTime: true });
  }, []);
  return (
    <DashboardLayout
      onSearch={(value) => {
        setPageData({ page: 1 });
        getList({ search: value, firstTime: true, currentPage: 1 });
      }}
    >
      <OrderListComponent
        pageData={pageData}
        setPageData={setPageData}
        filter={["OPEN"]}
        setFilter={() => {}}
        isOpenLeadOnly={true}
        getList={getList}
        getAllLiveUser={getList}
        TeamsData={leads}
        listTitle={"Open Lead"}
      />
    </DashboardLayout>
  );
};

export default OpenLeadExpert;
