import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../../../services/action/common";
import ImageUrlList from "../../../manager/leads/components/ImageUrlList";
import Buttons from "../../../utils/buttons/Buttons";
import { FormGroup } from "@mui/material";
import Icons from "../../../utils/Icons";
import CustomModal from "../../../utils/modals/CustomModal";
import EditorText from "../../../utils/text-editor/EditorText";
import { removeUnnecessaryTags } from "../../../utils/functions/functions";
import {
  getLeadsAction,
  orderDeliveredAction,
  sendBalancedAmountAction,
} from "../../../../services/action/manager";
import { Toaster } from "../../../Toaster";

const DeliveryConfirmation = ({
  sendMessage,
  setDeliveryConfirmation,
  deliveryConfirmation,
}) => {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const { leadDetail } = useSelector((state) => state.commonReducer);
  const imageRef = useRef();
  const [imageUrls, setImageUrls] = useState([]);
  const [screenshots, setScreenshots] = useState([]);
  const [messageContent, setMesageContent] = useState("");
  const { isImageLoading } = useSelector((state) => state.commonReducer);
  const { chatList } = useSelector((state) => state.managerReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [fileName, setFileName] = useState("");
  const [balancePayment, setBalancePayment] = useState("");

  const isBalancePayment =
  leadDetail?.totalPayment > leadDetail?.paymentRecieved;
  const uploadImage = (e, tempFile) => {
    setFileName(tempFile);
    const callBackImage = (res) => {
      if (tempFile == "document") {
        let tempData = [...imageUrls];
        setImageUrls([...tempData, ...res?.imageUrls]);
      } else {
        let tempData = [...screenshots];
        setScreenshots([...tempData, ...res?.imageUrls]);
      }
      setFileName("");
    };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  const onSubmit = () => {
    let messageFile = "";
    if(isBalancePayment && screenshots?.length && balancePayment<1){
      Toaster.error("Balance Payment is required!")
      return false;
    }

    if(isBalancePayment && screenshots?.length<1 && balancePayment>0){
      Toaster.error("Payment Screenshots is required!")
      return false;
    }
    imageUrls?.map((item) => {
      messageFile =
        messageFile +
        `<a target='_blank' href=${item}><i class="fa fa-file" aria-hidden="true"></i></a>`;
    });

    const message = `<div class="mark-query-chat-box final-delivery-message">${removeUnnecessaryTags(
      messageContent
    )} <p class="file-list">${messageFile} </p></div>`;
    const userTypes = ["OPERATOR", "MANAGER"];
    const userType = user?.userType?.toLowerCase();
    if (chatList?.data?.paymentType == "FULL" || (screenshots?.length && balancePayment>0)) {
      userTypes.push("CLIENT");
      dispatch(
        sendBalancedAmountAction({
          userType,
          data: { _id: chatList?.data?.leadId, files: imageUrls, screenshots ,balancePayment},
        })
      );
    } else {
      dispatch(
        sendBalancedAmountAction({
          userType,
          data: { _id: chatList?.data?.leadId },
        })
      );
    }
    sendMessage(message, false, userTypes);
    setDeliveryConfirmation({});

    const obj = {
      _id: chatList?.data?.leadId,
      checkPayment: true,
    };

    const callBack = () => {
      dispatch(getLeadsAction({ userType }));
    };
    dispatch(orderDeliveredAction({ userType, data: obj, cb: callBack }));
  };

 

  const Content = () => {
    return (
      <FormGroup>
        <lable className="mb-2">
          Balance Amount :{" "}
          <b>
            {" "}
            {leadDetail?.totalPayment - leadDetail?.paymentRecieved}{" "}
            {leadDetail?.currancy}{" "}
          </b>
        </lable>
        <EditorText setValue={setMesageContent} value={messageContent} />
        <div
          className="delivery-box-input-file mt-2"
          onClick={() => fileRef.current?.click()}
        >
          <p className="m-0 text-center">Upload File</p>
          {isImageLoading && fileName == "document" && (
            <p className="m-0 text-center">
              <Icons title={"loader"} color="#0a376a" />
            </p>
          )}
        </div>
        <input
          type="file"
          onChange={(e) => uploadImage(e, "document")}
          className="display-none"
          ref={fileRef}
          multiple={true}
        />
        {!!imageUrls?.length && (
          <ImageUrlList
            items={imageUrls}
            onDelete={(image) => {
              let filterDocument = imageUrls?.filter((item) => item !== image);
              setImageUrls(filterDocument);
            }}
          />
        )}
        {isBalancePayment && (
          <Fragment>
            <div
              className="delivery-box-input-file mt-2"
              onClick={() => imageRef.current?.click()}
            >
              <p className="m-0 text-center">Upload Payment Screenshots {balancePayment > 0 && <label className="text-danger">*</label>}</p>
              {isImageLoading && fileName == "screenshots" && (
                <p className="m-0 text-center">
                  <Icons title={"loader"} color="#0a376a" />
                </p>
              )}
            </div>
            <input
              type="file"
              onChange={(e) => uploadImage(e, "screenshots")}
              className="display-none"
              ref={imageRef}
              multiple={true}
            />
            {!!screenshots?.length && (
              <ImageUrlList
                items={screenshots}
                onDelete={(image) => {
                  let filterDocument = screenshots?.filter(
                    (item) => item !== image
                  );
                  setScreenshots(filterDocument);
                }}
              />
            )}
          </Fragment>
        )}

        {isBalancePayment && (
          <Fragment>
            <label>Enter Amount {!!screenshots?.length && <span className="text-danger">*</span>}</label>
            <input
              required={screenshots?.required}
              type="text"
              value={balancePayment}
              onChange={(e) => setBalancePayment(e.target.value)}
              className="form-control mb-2"
            />
          </Fragment>
        )}
        <Buttons title={"Delivered"} onClick={onSubmit} />
      </FormGroup>
    );
  };
  return (
    <Fragment>
      <CustomModal
        open={deliveryConfirmation?.modal}
        title={"Deliver"}
        zIndex={10000}
        size="md"
        content={Content}
        onClose={() => setDeliveryConfirmation({})}
      />
    </Fragment>
  );
};

export default DeliveryConfirmation;
