import { useState } from "react";
import Icons from "../../utils/Icons";
import EmojiPicker from "emoji-picker-react";
const EmojiButton = ({ userType,sendMessage }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <span>
        <EmojiPicker
          onEmojiClick={(e) => {
            sendMessage(`<span class="custom-emoji">${e?.emoji}</span>`);
            setOpen(false);
          }}
          open={open}
          style={{ position: "absolute", bottom: "162px" }}
        />
        <Icons
          onClick={() => setOpen(!open)}
          title={"emoji"}
          className="pointer send-emoji"
          style={userType=="expert"?{marginRight:"10px"}:{}}
          color="#fff"
          size={20}
        />
      </span>
    </>
  );
};
export default EmojiButton;
