import React, { useEffect, useState } from "react";
import OrderListComponent from "../../components/manager/leads/OrderListComponent";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsAction } from "../../services/action/manager";

const OrderListOperator = () => {
  const dispatch = useDispatch();
  const { leads } = useSelector((state) => state.managerReducer);
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 15,
  });
  const initialDate={
    clientStartDate: null,
    clientEndDate: null,
    expertStartDate: null,
    expertEndDate: null,
    leadStartDate: null,
    leadEndDate: null,
  }
  const [dateRanges, setDateRanges] = useState(initialDate);
  const [filters, setFilter] = useState(["ALL_ORDERS"])  
  const [search,setSearch]=useState("")
  const getList = ({
    currentPage = pageData?.page,
    firstTime = false,
    isEmptylist = true,
    tempSearch = search,
    filter = filters,
    sortConfig,
    dateFilter=dateRanges,

  }) => {
    dispatch(
      getLeadsAction({
        userType: "operator",
        firstTime,
        data: {
          sortConfig,
          search:tempSearch,
          page: currentPage,
          isEmptylist,
          dateFilter: {
            clientDeadline: { startDate: dateFilter.clientStartDate, endDate: dateFilter.clientEndDate },
            expertDeadline: { startDate: dateFilter.expertStartDate, endDate: dateFilter.expertEndDate },
            leadRegistration: { startDate: dateFilter.leadStartDate, endDate: dateFilter.leadEndDate },
          },
          orderStatus: filter,
        },
      })
    );
  };
  
  useEffect(() => {
    getList({ currentPage: 1, firstTime: true });
  }, []);
  return (
    <DashboardLayout onSearch={(value) => {
      setPageData({page:1})
      setSearch(value)
      getList({ tempSearch: value,firstTime:true, currentPage:1})
    }}>
      <OrderListComponent
      setDateRanges={setDateRanges}
      dateRanges={dateRanges}
        getAllLiveUser={getList}
        setPageData={setPageData}
        pageData={pageData}
        getList={getList}
        TeamsData={leads}
        listTitle={"Order List"}
        filter={filters}
        setFilter={setFilter}
      />
    </DashboardLayout>
  );
};

export default OrderListOperator;
