// import node module libraries
import { useEffect, useState } from "react";

// import sub components
import NavbarVertical from "./navbars/NavbarVertical";
import NavbarTop from "./navbars/NavbarTop";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../services/action/authentication";
import { useNavigate } from "react-router-dom";
import ShowCallHippo from "./call-hippo/ShowCallHippo";
// import socket from "../socket/socket";

const DashboardLayout = (props) => {
  const [showMenu, setShowMenu] = useState(true);
  const { user } = useSelector((state) => state.authenticationReducer);
  const ToggleMenu = () => {
    return setShowMenu(!showMenu);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUserAction({ errorCb: () => navigate("/") }));
  }, [dispatch, navigate]);



  return (
    user && (
      <div id="db-wrapper" className={`${showMenu ? "" : "toggled"} `}>
        {user?.isAccessCallHippo && <ShowCallHippo />}
        <div className="navbar-vertical navbar">
          <NavbarVertical
            showMenu={showMenu}
            onClick={(value) => setShowMenu(value)}
          />
        </div>
        <div id="page-content">
          <div className="header">
            <NavbarTop
              onSearch={props?.onSearch}
              data={{
                showMenu: showMenu,
                SidebarToggleMenu: ToggleMenu,
              }}
            />
          </div>
          {props.children}
          <div className="px-6 border-top py-3">
            <Row>
              <Col sm={6} className="text-center text-sm-start mb-2 mb-sm-0">
                <p className="m-0">
                  Made by{" "}
                  <a href="#-" target="_blank">
                    ClickinPedia
                  </a>
                </p>
              </Col>
              <Col sm={6} className="text-center text-sm-end">
                <p className="m-0">
                  Destributed by{" "}
                  <a href="#-" target="_blank">
                    ClickinPedia
                  </a>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  );
};
export default DashboardLayout;
