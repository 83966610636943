import { Fragment, useState } from "react";
import CustomDatePicker from "../../../utils/date-picker/CustomDatePicker";
import Buttons from "../../../utils/buttons/Buttons";
import CustomModal from "../../../utils/modals/CustomModal";
import Icons from "../../../utils/Icons";

const DateRangePicker = ({ label, startDate, setStartDate, endDate, setEndDate, onSelect }) => (
  <div className="row">
    <div className="col-sm-6">
      <label>{label} (From)</label>
      <CustomDatePicker value={startDate} setValue={(date) => { setStartDate(date); onSelect(label); }} />
    </div>
    <div className="col-sm-6">
      <label>{label} (To)</label>
      <CustomDatePicker value={endDate} setValue={setEndDate} minDate={startDate} />
    </div>
  </div>
);

const RangeDateFilter = ({ setPageData, getList, filter ,setDateRanges,dateRanges}) => {
 

  const [isDateFilter, setIsDateFilter] = useState(false);

  const handleDateRangeSelect = (label) => {
    if (label !== "Client Deadline") setDateRanges((prev) => ({ ...prev, clientStartDate: null, clientEndDate: null }));
    if (label !== "Expert Deadline") setDateRanges((prev) => ({ ...prev, expertStartDate: null, expertEndDate: null }));
    if (label !== "Order Creation") setDateRanges((prev) => ({ ...prev, leadStartDate: null, leadEndDate: null }));
  };

  const clearFilter = () => {
    const initialValue={
      clientStartDate: null,
      clientEndDate: null,
      expertStartDate: null,
      expertEndDate: null,
      leadStartDate: null,
      leadEndDate: null,
    };
    setDateRanges(initialValue);
    getList({ currentPage: 1, filter, isEmptylist: true,dateFilter:initialValue });
    setIsDateFilter(false);
  };

  const validateDateRanges = () => {
    const { clientStartDate, clientEndDate, expertStartDate, expertEndDate, leadStartDate, leadEndDate } = dateRanges;
    const isClientFilled = clientStartDate || clientEndDate;
    const isExpertFilled = expertStartDate || expertEndDate;
    const isLeadFilled = leadStartDate || leadEndDate;

    if (
      (isClientFilled && (isExpertFilled || isLeadFilled)) ||
      (isExpertFilled && (isClientFilled || isLeadFilled)) ||
      (isLeadFilled && (isClientFilled || isExpertFilled))
    ) {
      alert("Please fill in only one date range at a time.");
      return false;
    }
    return true;
  };

  const applyFilter = () => {
    if (!validateDateRanges()) return;
    setPageData({ page: 1 });
    getList({
      currentPage: 1,
      filter,
      dateFilter: dateRanges,
      firstTime: true,
    });
    setIsDateFilter(false);
  };

  const Content = () => (
    <Fragment>
      <DateRangePicker
        label="Client Deadline"
        startDate={dateRanges.clientStartDate}
        setStartDate={(date) => setDateRanges((prev) => ({ ...prev, clientStartDate: date }))}
        endDate={dateRanges.clientEndDate}
        setEndDate={(date) => setDateRanges((prev) => ({ ...prev, clientEndDate: date }))}
        onSelect={handleDateRangeSelect}
      />
      <div className="col-md-12 text-center"><span>Or</span></div>
      <DateRangePicker
        label="Expert Deadline"
        startDate={dateRanges.expertStartDate}
        setStartDate={(date) => setDateRanges((prev) => ({ ...prev, expertStartDate: date }))}
        endDate={dateRanges.expertEndDate}
        setEndDate={(date) => setDateRanges((prev) => ({ ...prev, expertEndDate: date }))}
        onSelect={handleDateRangeSelect}
      />
      <div className="col-md-12 text-center"><span>Or</span></div>
      <DateRangePicker
        label="Order Creation"
        startDate={dateRanges.leadStartDate}
        setStartDate={(date) => setDateRanges((prev) => ({ ...prev, leadStartDate: date }))}
        endDate={dateRanges.leadEndDate}
        setEndDate={(date) => setDateRanges((prev) => ({ ...prev, leadEndDate: date }))}
        onSelect={handleDateRangeSelect}
      />
      <div className="col-md-12 text-center" style={{ marginTop: 20 }}>
        <Buttons title="Apply Filter" onClick={applyFilter} />
        <button className="btn btn-sm btn-danger ml-1" onClick={clearFilter}>Clear Filter</button>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <CustomModal onClose={() => setIsDateFilter(false)} size="md" open={isDateFilter} content={Content} />
      <span className="filter-icon" onClick={() => setIsDateFilter(!isDateFilter)}>
        <Icons color="#00234a" title="date-filter" />
      </span>
    </Fragment>
  );
};

export default RangeDateFilter;
