import React, { useEffect, useRef, useState } from "react";
import BasicClientDetail from "./BasicClientDetail";
import Buttons from "../../../utils/buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import {
  getExpertListOptionAction,
  uploadImageAction,
} from "../../../../services/action/common";
import { useForm } from "react-hook-form";
import {
  generateLeadActionManager,
  updateLeadsActionManager,
} from "../../../../services/action/manager";
import {
  LeadStatus,
  OrderStatus,
  planTypeList,
} from "../../../utils/TeamsData";
import FormFileUpload from "./FormFileUpload";
import FormPayment from "./FormPayment";
import { Toaster } from "../../../Toaster";
import { sourceList, subjectList } from "../../../constants/commonConstant";
import SelectBox from "../../../utils/select/SelectBox";

const FormEditLeadGenerate = ({ page, close, data }) => {
  const imageRef = useRef();
  const documentRef = useRef();
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getWatch = watch();
  const { isSaveLoading } = useSelector((state) => state.managerReducer);
  const [statusList, setStatusList] = useState([]);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [isScreenShot, setIsScreenShot] = useState(false);
  const isManager = user?.userType === "MANAGER";
  const isClient = user?.userType === "CLIENT";
  const isAdminOrSuperAdmin=user?.userType === "ADMIN" || user?.userType === "SUPER_ADMIN"
  const isOperator = user?.userType==="OPERATOR"

  const STATUS = (page === "order" && data?.leadStatus!=="NEW") ? "orderStatus" : "leadStatus";

  const dispatch = useDispatch();
  const uploadImage = (e, fieldName = "document") => {
    if (fieldName === "document") {
      setIsScreenShot(false);
    } else {
      setIsScreenShot(true);
    }
    const imageCallBack = (res) => {
      if (getWatch?.[fieldName]?.length) {
        setValue(fieldName, [...getWatch[fieldName], ...res?.imageUrls]);
      } else {
        setValue(fieldName, res?.imageUrls);
      }
    };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]); // Append each file to the FormData object
    }

    dispatch(uploadImageAction({ data: formData, cb: imageCallBack }));
    e.target.value = null;
  };

  const onSubmit = (obj) => {
    obj.createdBy = user?._id;
    const userType = user?.userType?.toLowerCase();

    if (getWatch[STATUS] === "PAID" && obj?.screenshots?.length < 1) {
      Toaster.error("Payment screenshots is required");
      return false;
    }
    if (data) {
      obj._id = data?._id;
      dispatch(updateLeadsActionManager({ userType, data: obj, cb: close }));
    } else {
      dispatch(generateLeadActionManager({ userType, data: obj, cb: close }));
    }
  };

  useEffect(() => {
    if (data) {
      setValue("subject", data?.subject);
      setValue("client", data?.client?._id);
      setValue("number", data?.client?.number);
      setValue("countryCode", data?.client?.countryCode);
      setValue("email", data?.client?.email);
      setValue("name", data?.client?.name);
      setValue("wordCount", data?.wordCount);
      setValue("referenceType", data?.referenceType);
      setValue("paymentRecieved", data?.paymentRecieved);
      setValue("document", data?.document);
      setValue("amount", data?.amount);
      setValue("screenshots", data?.screenshots);
      setValue("plan", data?.plan);
      setValue("paymentType", data?.paymentType);
     
      if (data?.clientDeadline) {
        const clientDeadlineUtc = moment.utc(data?.clientDeadline);
        const formattedClientDate = clientDeadlineUtc.tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm");
        setValue("clientDeadline", formattedClientDate);
      }
      
      // Convert expertDeadline to Asia/Kolkata timezone
      if (data?.expertDeadline) {
        const expertDeadlineUtc = moment.utc(data?.expertDeadline);
        const formattedExpertDate = expertDeadlineUtc.tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm");
        setValue("expertDeadline", formattedExpertDate);
      }

      setValue("totalPayment", data?.totalPayment);
      setValue("requirement", data?.requirement);
      setValue("category", data?.category);
      setValue(STATUS, data[STATUS]);
      setValue("currancy", data?.currancy);
      setValue("expert", data?.expert?._id || data?.expert );
      setValue("expertAmount", data?.expertAmount);
      setValue("countryCode", data?.client?.countryCode);
      setValue("source", data?.source);
    } else if (user?.userType === "CLIENT") {
      setValue("leadStatus", "NEW");
      setValue("countryCode", "+91");
      setValue("number", user?.number);
    } else {
      setValue("leadStatus", "NEW");
      setValue("countryCode", "+91");
    }
  }, [data, user, setValue, STATUS]);

  useEffect(() => {
    if (user && user?.userType==="CLIENT") {
      setValue("name", user?.name);
    }
  }, [user, setValue]);

  useEffect(() => {
    const userType = user?.userType?.toLowerCase();
    if (page === "order"  && data?.leadStatus!=="NEW") {
      setStatusList(OrderStatus);
    } else {
      setStatusList(LeadStatus);
    }
    if (userType && userType !== "client") {
      dispatch(getExpertListOptionAction({ userType }));
    }
  }, [page, setStatusList, dispatch, user,data]);


  const getSubject = () => {
    if (getWatch?.subject) {
      return { label: getWatch?.subject, value: getWatch?.subject };
    }
    return {};
  };
  return (
    <div className="row custom-form generate-lead-form">
      <BasicClientDetail
        getWatch={getWatch}
        setValue={setValue}
        errors={errors}
        register={register}
        data={data}
      />
      <div className="col-sm-6">
        <label className={errors?.subject && "text-danger"}>
          Subject<span className="text-danger">*</span>
        </label>
        <SelectBox
          creatable={true}
          options={subjectList}
          value={getSubject()}
          onChange={(value) => setValue("subject", value?.value)}
          disabled={!isManager && !isOperator  && !isAdminOrSuperAdmin && data}
        />
      </div>
      <div className={`col-sm-3`}>
        <label className={errors?.wordCount && "text-danger"}>
          Total Word Count<span className="text-danger">*</span>
        </label>
        <input
          {...register("wordCount", {
            required: true,
          })}
          type="number"
          disabled={!isManager && !isOperator && !isAdminOrSuperAdmin && data}
          className="form-control"
        />
      </div>
      {!isClient && (
        <div className="col-sm-3">
          <label>Source</label>
          <select
            className="form-control"
            {...register("source", {
              required: true,
            })}
            disabled={!isManager && !isOperator && !isAdminOrSuperAdmin}
          >
            {sourceList.map((item, index) => (
              <option key={index} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className={`col-sm-${isClient?"3":"6"}`}>
        <label>Reference Type</label>
        <input
          {...register("referenceType")}
          type="text"
          disabled={!isManager && !isOperator && !isAdminOrSuperAdmin && data}
          className="form-control"
        />
      </div>

      {!isClient && (
        <div className="col-sm-3">
          <label className={errors?.plan && "text-danger"}>
            Plan<span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            {...register("plan", {
              required: true,
            })}
            disabled={(!isManager && data?.plan) && !isAdminOrSuperAdmin}
          >
            {planTypeList.map((item, index) => (
              <option key={index} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>
      )}
      {!isClient && (
        <div className="col-sm-3">
          <label>Status</label>
          <select
            className="form-control"
            {...register(STATUS)}
            disabled={user?.userType === "CLIENT"}
          >
            {statusList?.map((item) => (
              <option
                selected={item?.value === getWatch[STATUS]}
                value={item?.value}
                key={item?.value}
                className="New"
              >
                {item?.label}
              </option>
            ))}
          </select>
        </div>
      )}

      <FormFileUpload
        uploadImage={uploadImage}
        imageRef={imageRef}
        isScreenShot={isScreenShot}
        setValue={setValue}
        getWatch={getWatch}
        documentRef={documentRef}
        data={data}
        STATUS={STATUS}
        errors={errors}
        
      />

      <FormPayment
        isManager={isManager}
        data={data}
        errors={errors}
        register={register}
        getWatch={getWatch}
        setValue={setValue}
        page={page}
        STATUS={STATUS}
        isAdminOrSuperAdmin={isAdminOrSuperAdmin}
        isOperator={isOperator}
      />
      <div className="col-sm-12">
        <label className={errors?.requirement && "text-danger"}>
          Requirement<span className="text-danger">*</span>
        </label>
        <textarea
          {...register("requirement", {
            required: true,
          })}
          style={{ height: 110 }}
          type="text"
          disabled={!isManager && !isOperator  && !isAdminOrSuperAdmin && data}
          className="form-control"
        />
      </div>
      <div className="col-sm-12 m-2 text-center">
        <Buttons
          isLoading={isSaveLoading}
          disabled={isSaveLoading}
          title={data ? "Update" : "Generate Lead"}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

export default FormEditLeadGenerate;
