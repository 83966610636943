import React, { useEffect } from 'react'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import Link from 'next/link';
import { Container, Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';

// import widget/custom components
import StatRightTopIcon from "../../components/utils/StatRightTopIcon";

// import sub components
import LeadListDashboard from '../../components/utils/LeadListDashboard';
// import required data files
import { DashboardCardClient } from "../../components/utils/ProjectsStatsData";
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardAction } from '../../services/action/common';

const ClientDashboard = () => {
    const dispatch = useDispatch();
    const { dashboardData, isDashboardLoading } = useSelector((state) => state.commonReducer)
    useEffect(() => {
        dispatch(getDashboardAction({ userType: "client" }))
    }, [])

    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    return (
        <DashboardLayout>
            <div className="pt-10 pb-21 bg-blue-900">
                <Container fluid className="mt-n22 px-6">
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <div>
                                <div className="flex justify-between items-center mb-6">
                                    <h1 className="text-2xl font-bold text-white">Hi Satendra</h1>
                                    <p className="text-blue-300">Ready to excel?</p>
                                </div>
                            </div>
                        </Col>
                        {DashboardCardClient(dashboardData).map((item, index) => (
                            <Col xl={4} lg={4} md={12} xs={12} className="mt-6" key={index}>
                                <motion.div
                                    variants={cardVariants}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{ delay: index * 0.1 }}
                                    className="bg-blue-800 rounded-lg p-4 text-white"
                                >
                                    <div className="text-3xl font-bold mb-2">{item.value || '0'}</div>
                                    <div className="text-sm text-blue-300">{item.title}</div>
                                </motion.div>
                            </Col>
                        ))}
                        <Col xl={4} lg={4} md={12} xs={12} className="mt-6">
                            <motion.div
                                variants={cardVariants}
                                initial="hidden"
                                animate="visible"
                                transition={{ delay: 0.2 }}
                                className="bg-blue-800 rounded-lg p-4 text-white"
                            >
                                <div className="text-3xl font-bold mb-2">3</div>
                                <div className="text-sm text-blue-300">Ongoing</div>
                            </motion.div>
                        </Col>
                        <Col xl={4} lg={4} md={12} xs={12} className="mt-6">
                            <motion.div
                                variants={cardVariants}
                                initial="hidden"
                                animate="visible"
                                transition={{ delay: 0.3 }}
                                className="bg-blue-800 rounded-lg p-4 text-white"
                            >
                                <div className="text-3xl font-bold mb-2">$120</div>
                                <div className="text-sm text-blue-300">Balance</div>
                            </motion.div>
                        </Col>
                    </Row>

                {/* Active Projects  */}
                <LeadListDashboard data={dashboardData?.leadList} isClient={true} />

                </Container>
            </div>
        </DashboardLayout>
    )
}

export default ClientDashboard