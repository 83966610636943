import React, { Fragment, useEffect, useState } from "react";
import { currancyList } from "../../utils/TeamsData";
import Buttons from "../../utils/buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrencyListAction,
  updateCurrencyAction,
} from "../../../services/action/common";

const UpdateCurrencyForm = () => {
  const [currencies, setCurrencies] = useState([]);
  const dispatch = useDispatch();
  const { isUpdateCurrency, currency } = useSelector(
    (state) => state.commonReducer
  );
  const { user } = useSelector((state) => state.authenticationReducer);
  useEffect(() => {
    setCurrencies(currancyList?.map((item) => ({ label: item.value })));
  }, [currancyList]);

  const update = () => {
    dispatch(
      updateCurrencyAction({
        userType: user?.userType?.toLowerCase(),
        data: currencies,
      })
    );
  };

  useEffect(()=>{
   if(currency){
    let tempCurrency=currency?.map((item)=>({
        label:item?.currencyName,
        value:item?.currencyAmount
    }))
    setCurrencies(tempCurrency)
   }
  },[currency])

  useEffect(() => {
    dispatch(
      getCurrencyListAction({ userType: user?.userType?.toLowerCase() })
    );
  }, [dispatch]);
  return (
    <Fragment>
      <div className="form">
        <div className="container">
          <div className="row">
            {currencies?.map(
              (item, index) =>
                item?.label && (
                  <div className="col-6">
                    <label>{item?.label}</label>
                    <input
                      type="text"
                      value={item?.value}
                      onChange={(e) => {
                        let currentCurrency = [...currencies];
                        currentCurrency[index].value = e?.target?.value;
                        setCurrencies(currentCurrency);
                      }}
                      className="form-control"
                    />
                  </div>
                )
            )}
            <div className="col-6 mt-5">
              <Buttons
                isLoading={isUpdateCurrency}
                title={"Update"}
                onClick={update}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateCurrencyForm;
