import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAction } from "../../services/action/manager";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import ManagerListComponents from "../../components/manager/users/managers/ManagerList";
const ManagerList = () => {
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.managerReducer);

  const getList = ({ currentPage, keyword = "", firstTime = true }) => {
    dispatch(
      getAllUsersAction({
        userType:"super_admin",
        firstTime,
        data: { userType: "MANAGER", page: currentPage,  keyword },
      })
    );
  };
  useEffect(() => {
    getList({ firstTime: true});
  }, []);
  return (
    <DashboardLayout
      onSearch={(e) =>
        getList({ currentPage: 1, firstTime: false, keyword: e })
      }
    >
      <ManagerListComponents getList={getList} TeamsData={userList} />
    </DashboardLayout>
  );
};

export default ManagerList;
