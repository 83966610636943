import { Tooltip } from "@mui/material";
import Buttons from "../utils/buttons/Buttons";
import { returnDateWithTime } from "../utils/functions/functions";
import { Fragment } from "react";

export const ColumnQueries = (setShowMessage,updateQueryStatus,recentResolved) => {
  return [
    {
      name: "Sr.No",
      selector: (_, index) => index + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "Date",
      selector: (row) => returnDateWithTime(row?.createdAt),
      sortable: true,
      width: "149px",
    },
    {
      name: "OrderId",
      selector: (row) => row?.orderId || "", // Ensure valid data
      sortable: true,
      width: "129px",
    },
    {
      name: "Subject",
      selector: (row) => row?.subject || "", // Ensure valid data
      sortable: true,
    },
    {
      name: "Expert Name",
      selector: (row) => row?.expertName || "", // Ensure valid data
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row?.message || "", 
      sortable: true,
      cell: (row) => (
        <Fragment>
          <Tooltip title="View Message">
            <Buttons
              onClick={() => setShowMessage(row?.message)}
              title={"View Query"}
            />
          </Tooltip>
          <Buttons
            style={{ marginLeft: 3 }}
            variant="outlined"
            onClick={() => updateQueryStatus({leadId:row?.leadId,queryId:row?.queryId})}
            disabled={row?.status==="CLOSED" || recentResolved?.includes(row?.queryId)}
            title={(row?.status==="CLOSED" || recentResolved?.includes(row?.queryId)) ?"Resolved":"Resolve"}
          />
        </Fragment>
      ),
      width:"220px"
    },
    {
      name: "Resolve By",
      selector: (row) => row?.updatedByName || "NA",
      sortable: true,
    },
  ];
};
