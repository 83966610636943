import React from "react";
import ChipsDeletable from "../../../utils/chips/ChipsDeletable";
import { getFileNameByUrl } from "../../../utils/functions/functions";

const ImageUrlList = ({ items ,onDelete}) => {
const onClick=(link)=>{
    window.open(link)
}
  return (
    <ul className="image-list-custom">
      {Array.isArray(items) && 
      items?.map((item, index) => (
        <li key={index}>
          <ChipsDeletable onDelete={()=>onDelete(item)} onClick={()=>onClick(item)} title={getFileNameByUrl(item)} />
        </li>
      ))}
    </ul>
  );
};

export default ImageUrlList;
