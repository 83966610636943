import React, { useEffect } from 'react'
import ClientListCompoent from '../../components/manager/users/clients/ClientListCompoent';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersAction } from '../../services/action/manager';
import DashboardLayout from '../../components/layouts/DashboardLayout';

const MarketingSA = () => {
  const dispatch = useDispatch();
  const {userList}=useSelector((state)=>state.managerReducer);
  const getList=({ currentPage,firstTime=true,keyword="" })=>{
    dispatch(getAllUsersAction({ 
      userType:"super_admin",
      firstTime,
      data: { userType: "MARKETING",page:currentPage,keyword } }));
  }
  useEffect(() => {
    getList({currentPage:1,firstTime:true})
  }, []);
  return (
    <DashboardLayout  onSearch={(e)=>getList({currentPage:1,isEmptylist:false,keyword:e})}>
    <ClientListCompoent listType="MARKETING" listTitle='Marketings List' getList={getList} TeamsData={userList}/>
    </DashboardLayout>
  )
}

export default MarketingSA