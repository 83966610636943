// import { X } from 'lucide-react'

import {  useSelector } from "react-redux";
import { returnDateWithTime } from "../utils/functions/functions";
import Icons from "../utils/Icons";
const versionHistory = [
  {
    actions: "Created",
    author: {
      name: "Satendra",
      _id: "66d69b578041ebb0331da7ca",
      userType: "CLIENT",
    },
    description: "Lead Created",
    timeAgo: "2024-10-26T11:47:51.000Z",
    _id: "671cd6e779ca1b9f3c1250b5",
  },
  {
    actions: "Updated",
    author: {
      name: "Testing Operator",
      _id: "66de9d80783fb3080bf75544",
      userType: "OPERATOR",
    },
    description:
      "Converted Lead Status from NEW to PAID; Converted Order Status from  to OPEN",
    timeAgo: "2024-10-26T12:40:34.000Z",
    _id: "671ce342cdb3d59867641852",
  },
  {
    actions: "Updated",
    author: {
      name: "Testing Operator",
      _id: "66de9d80783fb3080bf75544",
      userType: "OPERATOR",
    },
    description: "Converted Order Status from OPEN to ASSIGNED",
    timeAgo: "2024-10-26T12:44:39.000Z",
    _id: "671ce437cdb3d598676418b3",
  },
];

const getClass = {
  Created: "bg-success text-white",
  Updated: "btn-primary text-white",
  Reopen: "bg-warning text-white",
  Closed: "bg-danger text-white",
};

export default function OrderHistoryActivity({ onClose }) {
  const {orderActivity}=useSelector((state)=>state.commonReducer)

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Order Activity</h2>
        <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
          <Icons title={"cross"} color="#000" />
        </button>
      </div>
      <div className="space-y-6">
        {orderActivity?.map((entry, index) => (
          <div key={index} className="flex">
            <div className="flex flex-col items-center mr-4">
              <div
                className={`w-20 h-18 rounded-full flex items-center justify-center ${
                  getClass[entry?.actions]
                }`}
              >
                <span className="text-xs font-semibold">{entry?.actions}</span>
              </div>
              {index < versionHistory.length - 1 && (
                <div className="w-0.5 h-full bg-gray-200 mt-2"></div>
              )}
            </div>
            <div>
              <p className="font-medium">{entry?.description}</p>
              <p className="text-sm text-gray-500">
                {entry?.author?.name} • {entry?.author?.userType} • {returnDateWithTime(entry.timeAgo)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
