export const appActions = {
  //Authentication
  LOGIN: "LOGIN",
  GET_USER:"GET_USER",
  GET_ALL_USER_ACTION:"GET_ALL_USER_ACTION",
  CREATE_USER:"CREATE_USER",
  DELETE_USER_MANAGER:"DELETE_USER_MANAGER",
  UPDATE_USER_MANAGER:"UPDATE_USER_MANAGER",
  UPLOAD_IMAGE:"UPLOAD_IMAGE",
  GET_CLIENT_LIST_OPTION_MANAGER:"GET_CLIENT_LIST_OPTION_MANAGER",
  GENERATE_LEAD_MANAGER:"GENERATE_LEAD_MANAGER",
  GET_GENERATE_LEAD_ADMIN:"GET_GENERATE_LEAD_ADMIN",
  UPDATE_LEAD_MANAGER:"UPDATE_LEAD_MANAGER",
  START_CHAT:"START_CHAT",
  GET_CHAT:"GET_CHAT",
  GET_DASHBOARD:"GET_DASHBOARD",
  CLOSE_CHAT:"CLOSE_CHAT",
  GET_EXPERT_LIST_OPTION:"GET_EXPERT_LIST_OPTION",
  GET_NOTIFICATION_LIST:"GET_NOTIFICATION_LIST",
  UPDATE_NOTIFICATION:"UPDATE_NOTIFICATION",
  UPDATE_NOTIFICATION_STATUS:"UPDATE_NOTIFICATION_STATUS",
  GENERATE_PAYMENT_LINK:"GENERATE_PAYMENT_LINK",
  LOGOUT:"LOGOUT",
  GET_COUNTRY_LIST:"GET_COUNTRY_LIST",
  UPLOAD_AUDIO:"UPLOAD_AUDIO",
  CHANGE_CHAT_VISIBITY:"CHANGE_CHAT_VISIBITY",
  RESET_PASSWORD:"RESET_PASSWORD",
  UPDATE_PASSWORD:"UPDATE_PASSWORD",
  SENT_PAYMENT_LINK_WHATSAPP:"SENT_PAYMENT_LINK_WHATSAPP",
  CHANGE_CALL_HIPPO_PERMISSION:"CHANGE_CALL_HIPPO_PERMISSION",
  GET_OPERATOR_MANAGER_LIST_ACTION:"GET_OPERATOR_MANAGER_LIST_ACTION",
  ORDER_DELIVERED_ACTION:"ORDER_DELIVERED_ACTION",
  SEND_BALANCED_AMOUNT_TO_CLIENT:"SEND_BALANCED_AMOUNT_TO_CLIENT",
  REQUEST_FOR_ASSIGN_LEAD:"REQUEST_FOR_ASSIGN_LEAD",
  GET_ALL_LIVE_USER_LIST:"GET_ALL_LIVE_USER_LIST",
  GET_OPEN_USER_CHAT_LIST:"GET_OPEN_USER_CHAT_LIST",
  GET_OPEN_NOTIFICATION:"GET_OPEN_NOTIFICATION",
  START_OPEN_CHAT:"START_OPEN_CHAT",
  CLOSE_OPEN_CHAT:"CLOSE_OPEN_CHAT",
  SET_NOTIFICATION_PERMISSION:"SET_NOTIFICATION_PERMISSION",
  GET_REPORTS:"GET_REPORTS",
  GET_LEAD_DETAILS:"GET_LEAD_DETAILS",
  GET_EXPERT_QUERIES:"GET_EXPERT_QUERIES",
  UPDATE_QUERY_STATUS:"UPDATE_QUERY_STATUS",
  UPDATE_CURRENCY:"UPDATE_CURRENCY",
  GET_CURRENCY_LIST:"GET_CURRENCY_LIST",
  UNASSIGNED_LEAD:"UNASSIGNED_LEAD",
  VERIFY_GOOGLE_TOKEN:"VERIFY_GOOGLE_TOKEN",
  SIGNUP:"SIGNUP",
  GET_ORDER_ACTIVITY:"GET_ORDER_ACTIVITY",
  SET_PENALTY:"SET_PENALTY",
  GET_WELCOME_SCREEN_DATA:"GET_WELCOME_SCREEN_DATA",
  ON_UPDATE_ANDROID_DESIGN:"ON_UPDATE_ANDROID_DESIGN"
};
