import React, { useEffect } from 'react'
import Buttons from '../../../../utils/buttons/Buttons'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setPenaltyAction } from '../../../../../services/action/common'

const ExpertPenality = ({data,close}) => {
   const  {user}=useSelector((state)=>state.authenticationReducer)
   const dispatch=useDispatch();
   const {
      register,
      handleSubmit,
      setValue,
      formState:{errors}
   }=useForm();

   useEffect(()=>{
    if(data?.penality){
      setValue("amount",data?.penality?.amount)
      setValue("reason",data?.penality?.reason)
    }
   },[data])
  
   const onSubmit=(tempData)=>{
      const payload={
         ...tempData,
         leadId:data?._id
      }
      const userType=user?.userType?.toLowerCase();
      dispatch(setPenaltyAction({userType,data:payload,cb:close}))




   }
  return (
    <div className='container'>
        <form className='form'>
        <div className='row'>
           <div className='col-12'>
              <label className={errors?.amount && "text-danger"}>Amount<span className='text-danger'>*</span></label>
              <input {...register("amount",{
               required:true
              })} type='number' className='form-control'/>
           </div>
           <div className='col-12'>
              <label className={errors?.reason && "text-danger"}>Reason<span className='text-danger'>*</span></label>
              <textarea {...register("reason",{
               required:true
              })} type='text' className='form-control'/>
           </div>
           <div className='col-12'>
              <Buttons onClick={handleSubmit(onSubmit)} style={{marginTop:10}} title={"Set Penalty"}/>
           </div>
        </div>
        </form>
    </div>
  )
}

export default ExpertPenality