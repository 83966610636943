
import { Switch } from "@mui/material";
import { Image } from "react-bootstrap";

export const ClientListColumn = (
    setOperatorDetail,
    updateStatus
) => {

  const columns = [
    {
        name: "Sr. No",
        selector: (_,i) => i+1, // Provide something sortable
        width: "70px",
        sortable: true,
      },
    {
      name: "Contact",
      selector: (row) => row?.contactDetails?.length?"Yes":"NA", // Provide something sortable
      width: "89px",
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => row?.orderId || "NA",
      cell: (row) => (
        <td className="align-middle">
        <div className="d-flex align-items-center">
          <div className="width-37">
            <Image
              src={row.image}
              alt=""
              className="avatar-md avatar rounded-circle"
            />
          </div>
        </div>
      </td>
      ),
      width: "80px",
      sortable: true,
    },
    {
        name: "Name",
        selector: (row) => row?.name || "NA",
        cell:(row)=>(
            <td onClick={() =>
                setOperatorDetail({
                  data: row,
                  preview: true,
                  modal: false,
                })} style={{color:"#0a376a"}}> 
               {row?.name}
            </td>
        ),
        width: "150px",
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row?.email || "NA",
        width: "250px",
        sortable: true,
      },
      {
        name: "Role",
        selector: (row) => row?.userType || "NA",
        width: "150px",
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row?.userType || "NA",
        cell:(row)=>(
            <Switch
            inputProps={{ "aria-label": "Switch demo" }}
            checked={row.status === "ACTIVE"}
            onClick={() => updateStatus(row)}
          />
        ),
        width: "150px",
        sortable: true,
      },
 
  
  ];

  return columns?.filter(Boolean);
};
