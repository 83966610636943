import DashboardLayout from "./../../components/layouts/DashboardLayout";
import LiveUserTrack from "../../components/live-users/LiveUserTrack";
import { useEffect, useState } from "react";
import { getAllLiveUserListAction } from "../../services/action/common";
import { useDispatch, useSelector } from "react-redux";

const LiveUser = () => {
  const {user}=useSelector((state)=>state.authenticationReducer)
  const [filter, setFilter] = useState({
    searchQuery: "",
    website: "all website",
    isOnline: undefined,
    onlyTodayUser: undefined,
    from: undefined,
    to: undefined,
  });
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 17,
  });

  const dispatch=useDispatch();
  const getAllLiveUser = ({
    page = pageData?.page,
    limit = pageData?.limit,
    tempWebsite = filter.website,
    tempIsOnline = filter?.isOnline,
    tempSearchQuery = filter.searchQuery,
    tempOnlyTodayUser = filter.onlyTodayUser,
    from = filter.from,
    to = filter.to,
    firstTime = false,
  }) => {
    let userType = user?.userType?.toLowerCase();
    const tempFilter = {
      website: tempWebsite,
      isOnline: tempIsOnline,
      searchQuery: tempSearchQuery,
      limit,
      onlyTodayUser: tempOnlyTodayUser,
      from,
      to,
      page,
    };
    dispatch(
      getAllLiveUserListAction({
        firstTime,
        userType,
        filter: tempFilter,
      })
    );
  };

  useEffect(() => {
    if (user?.userType) {
      getAllLiveUser({ firstTime: true });
    }
  }, [user]); 
  
  return (
    <DashboardLayout onSearch={(value)=>{
      setFilter({...filter,searchQuery:value})
      setPageData({page:1})
      getAllLiveUser({tempSearchQuery:value,page:1, firstTime: true });
      
    }}>
        <LiveUserTrack getAllLiveUser={getAllLiveUser} filter={filter} setFilter={setFilter} setPageData={setPageData} pageData={pageData}/>
    </DashboardLayout>
  );
};
export default LiveUser;
