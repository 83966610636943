import React, { Fragment, useState } from "react";
import CustomModal from "../../utils/modals/CustomModal";
import UserConatactContent from "./UserConatactContent";

const UserContactDetails = ({ contactDetails }) => {
  const [contactList, setContactList] = useState("");
  return (
    <Fragment>
      <CustomModal
        open={contactList}
        onClose={() => setContactList("")}
        content={() => <UserConatactContent data={contactList} />}
      />
      <button
        onClick={() => setContactList(contactDetails)}
        className="bg-[#00234a] text-white text-sm py-1 px-4 rounded-md hover:bg-opacity-90 transition duration-300 mt-2"
      >
        View Contact
      </button>
    </Fragment>
  );
};

export default UserContactDetails;
