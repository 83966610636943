import { Tooltip } from "@mui/material";
import Buttons from "../../utils/buttons/Buttons";
import {
  convertSecondsToMinutes,
  returnDateWithTime,
  truncateString,
} from "../../utils/functions/functions";

export const ColumnLiveUser = (startChat, isMarketing) => {
  return [
    {
      name: "Visitor Id",
      selector: (row) => row?.visitorId,
      sortable: true,
      width: "119px",
    },
    {
      name: "User Name",
      selector: (row) => row?.name || "NA",
      sortable: true,
      maxWidth: "219px",
    },
    {
      name: "Online",
      selector: (row) => (
        <span className={`${row?.isOnline ? "text-success" : "text-danger"}`}>
          {row?.isOnline ? "Online" : "Offline"}
        </span>
      ),
      width: "89px",
      sortable: true,
    },
    !isMarketing && {
      name: "Action",
      selector: (row) => (
        <Buttons
          title={"Start Chat"}
          size="small"
          onClick={() => startChat(row)}
        />
      ),
      width: "129px",
      sortable: false,
    },
    {
      name: "Website",
      selector: (row) => <Tooltip title={row?.page}>{row?.website} </Tooltip>,
      width: "219px",
      sortable: true,
    },
    {
      name: "Logged Time",
      selector: (row) => returnDateWithTime(row?.timestamp),
      sortable: true,
    },
    !isMarketing && {
      name: "Email",
      selector: (row) => row?.email || "NA",
      sortable: true,
    },
    !isMarketing && {
      name: "Number",
      selector: (row) => row?.number || "NA",
      sortable: true,
    },
    {
      name: "Page",
      selector: (row) => (
        <Tooltip title={row?.page}>{truncateString(row?.page, 39)}</Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Time Spent",
      selector: (row) => convertSecondsToMinutes(row?.totalTimeSpent),
      sortable: true,
    },
    {
      name: "Country Name",
      selector: (row) => row?.countryName || "NA",
      sortable: true,
    },
    {
      name: "Visit Count",
      selector: (row) => row?.visits || "NA",
      sortable: true,
    },
    {
      name: "Browser",
      selector: (row) => row?.browser || "NA",
      sortable: true,
    },
    {
      name: "Refral",
      selector: (row) => (
        <Tooltip title={row?.refralUrl}>
          {truncateString(row?.refralUrl, 39)}
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "IP",
      selector: (row) => row?.clientIp || "NA",
      sortable: true,
    },
    {
      name: "Device",
      selector: (row) => row?.deviceType || "NA",
      sortable: true,
    },
  ];
};
