import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  BookOpen,
  Home,
  LogIn,
  MessageCircle,
  Search,
  Star,
  User,
  ArrowRight,
  UserPlus,
  AlignJustify,
} from "lucide-react";
import { MobileAppPremiumServices, MobileServiceIcons } from "../../../components/constants/commonConstant";

const MobileAppPreview = ({ editorData }) => {
  const [activeTab, setActiveTab] = useState("home");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div className="mobile-preview">
    <div style={{width:"430px"}} className="bg-gray-200 p-4 overflow-hidden margin-auto">
      <div className="bg-white rounded-lg shadow-sm overflow-hidden h-full">
        <div className="flex flex-col h-screen bg-gray-50">
          {/* App Bar */}
          <header
            className="text-white shadow-md"
            style={{ backgroundColor: "#0A376A" }}
          >
            <div className="flex items-center justify-between p-4">
              <div className="flex items-center space-x-2">
                <AlignJustify />
              </div>
              <div className="flex items-center space-x-2">
                <button className="flex items-center px-3 py-2 text-sm font-medium text-white hover:bg-white/20 transition-colors duration-300 rounded-md">
                  <LogIn className="h-4 w-4 mr-2" />
                  Login
                </button>
                <button className="flex items-center px-3 py-2 text-sm font-medium bg-white text-[#0A376A] hover:bg-opacity-90 transition-colors duration-300 rounded-md">
                  <UserPlus className="h-4 w-4 mr-2" />
                  Register
                </button>
              </div>
            </div>
          </header>

          {/* Main Content */}
          <main className="flex-1 overflow-y-auto">
            <div className="p-4 space-y-6">
              {/* Hero Card */}
              <div className="bg-gradient-to-br from-[#0A376A] to-[#1E5DA0] rounded-lg overflow-hidden shadow-md">
                <div className="p-6">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="space-y-4"
                  >
                    <h2 className="text-2xl font-bold text-white">
                      {editorData?.welcome?.title}
                    </h2>
                    <p className="text-white/80">
                      {editorData?.welcome?.subtitle}
                    </p>
                    <button className="px-4 py-2 bg-white text-[#0A376A] font-medium rounded-md hover:bg-opacity-90 transition-colors duration-300">
                      {editorData?.welcome?.buttonText}
                    </button>
                  </motion.div>
                </div>
              </div>

              {/* Services Grid */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold text-black">
                  Our Services
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  {editorData?.services.map((service, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <div className="bg-white p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow">
                        <div className="flex flex-col items-center text-center space-y-2">
                          <div className={`p-3 rounded-xl`} style={{
                            backgroundColor:service?.color
                          }}>
                          {MobileServiceIcons(index)}
                          </div>
                          <p className="text-sm font-medium text-black">
                            {service?.name}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <button className="w-full mt-4 px-4 py-2 bg-white text-[#0A376A] font-medium rounded-md border border-[#0A376A] hover:bg-[#0A376A] hover:text-white transition-colors duration-300">
                  999+ Services Available
                </button>
              </section>

              {/* Premium Academic Services Slider */}
              <section className="space-y-4">
                <div className="relative overflow-hidden rounded-2xl">
                  <motion.div
                    className="flex"
                    animate={{ x: `-${currentSlide * 100}%` }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  >
                    {editorData?.premiumServices.map((service, index) => (
                      <motion.div
                        key={index}
                        className="w-full flex-shrink-0 px-1"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ delay: index * 0.1 }}
                      >
                        <div
                          className={`rounded-lg overflow-hidden`}
                        >
                          <div className="p-1">
                            <div className="flex items-start justify-between">
                              {/* <div className="space-y-4"> */}
                              <img src={service?.image} alt=""/>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </motion.div>
                  <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center gap-2">
                    {editorData?.premiumServices?.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setCurrentSlide(index)}
                        className={`w-2 h-2 rounded-full transition-all duration-300 ${
                          index === currentSlide
                            ? "bg-white w-4"
                            : "bg-white/50"
                        }`}
                        aria-label={`Go to slide ${index + 1}`}
                      />
                    ))}
                  </div>
                </div>
              </section>

              {/* Our Brands Section */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold text-black">Our Brands</h3>
                <div className="grid grid-cols-2 gap-4">
                  {editorData?.brands.map((brand, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <div
                        className="rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow overflow-hidden h-24"
                        style={{ backgroundColor: brand?.bgColor }}
                      >
                        <div className="p-4 flex items-center justify-center h-full">
                          <img
                            src={brand?.logo}
                            alt={brand?.name}
                            className="max-w-full max-h-full object-contain"
                          />
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <button className="w-full mt-4 px-4 py-2 bg-white text-[#0A376A] font-medium rounded-md border border-[#0A376A] hover:bg-[#0A376A] hover:text-white transition-colors duration-300">
                  99+ Partners
                </button>
              </section>

              {/* Student Reviews Section */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold text-black">
                  Student Reviews
                </h3>
                <div className="space-y-4">
                  {editorData?.reviews.map((review, i) => (
                    <motion.div
                      key={i}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: i * 0.1 }}
                    >
                      <div className="bg-gradient-to-r from-[#0A376A] to-[#1E5DA0] text-white rounded-lg overflow-hidden">
                        <div className="p-4">
                          <div className="flex items-center gap-4">
                            <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center text-[#0A376A] font-bold text-xl border-2 border-white">
                              {review?.name[0]}
                            </div>
                            <div className="flex-1">
                              <p className="font-semibold">{review?.name}</p>
                              <p className="text-sm opacity-70">
                                {review?.course}
                              </p>
                              <div className="flex items-center gap-1 mt-1">
                                {Array.from({ length: review?.rating }).map(
                                  (_, i) => (
                                    <Star
                                      key={i}
                                      className="h-4 w-4 fill-yellow-400 text-yellow-400"
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <p className="mt-3 text-sm italic">
                            "{review?.comment}"
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <button className="w-full mt-4 px-4 py-2 bg-white text-[#0A376A] font-medium rounded-md border border-[#0A376A] hover:bg-[#0A376A] hover:text-white transition-colors duration-300">
                  999+ Reviews
                </button>
              </section>
            </div>
          </main>

          {/* Floating Action Button */}
          <button className="fixed bottom-20 right-4 h-14 w-14 rounded-full shadow-lg bg-[#0A376A] hover:bg-[#0A376A]/90 transition-all duration-300 flex items-center justify-center">
            <MessageCircle className="h-6 w-6 text-white" />
          </button>

          {/* Bottom Navigation */}
          <nav className="sticky bottom-0 bg-white border-t shadow-lg">
            <div className="flex justify-around items-center">
              {[
                { icon: Home, label: "Home", value: "home" },
                { icon: BookOpen, label: "Services", value: "services" },
                { icon: Search, label: "Search", value: "search" },
                { icon: User, label: "Profile", value: "profile" },
              ].map((item) => (
                <button
                  key={item.value}
                  className={`flex-1 flex flex-col items-center justify-center h-16 gap-1 ${
                    activeTab === item.value
                      ? "text-[#0A376A] border-t-2 border-[#0A376A]"
                      : "text-gray-500"
                  }`}
                  onClick={() => {
                    if (item.value === "search") setIsSearchOpen(!isSearchOpen);
                    else setActiveTab(item.value);
                  }}
                >
                  <item.icon className="h-5 w-5" />
                  <span className="text-xs">{item.label}</span>
                </button>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </div>
    </div>
  );
};

export default MobileAppPreview;
