// import node module libraries
// import Link from 'next/link';
import { Container, Col, Row } from 'react-bootstrap';

// import widget/custom components
import StatRightTopIcon from "../../components/utils/StatRightTopIcon";

// import sub components
// import { ActiveProjects, Teams, TasksPerformance } from "./"
// import required data files
import ProjectsStatsData from "../../components/utils/ProjectsStatsData";
import DashboardLayout from "./../../components/layouts/DashboardLayout"
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getDashboardAction } from '../../services/action/common';
import LeadListDashboard from '../../components/utils/LeadListDashboard';

const ManagerDashboard = () => {
    const dispatch=useDispatch();
    const {dashboardData,isDashboardLoading}=useSelector((state)=>state.commonReducer)
    useEffect(()=>{
    dispatch(getDashboardAction({userType:"manager"}))
    },[])
    // console.log("dashboardData",dashboardData)
    return (
        <DashboardLayout>
            <div className=" pt-10 pb-21" style={{backgroundColor: '#00234a'}}></div>
            <Container fluid className="mt-n22 px-6">
                <Row>
                    <Col lg={12} md={12} xs={12}>
                        {/* Page header */}
                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mb-2 mb-lg-0">
                                    <h3 className="mb-0  text-white">Projects</h3>
                                </div>
                                {/* <div>
                                    <Link href="#" className="btn btn-white">Create New Lead</Link>
                                </div> */}
                            </div>
                        </div>
                    </Col>
                    {ProjectsStatsData(dashboardData).map((item, index) => {
                        return (
                            <Col xl={3} lg={6} md={12} xs={12} className="mt-6" key={index}>
                                <StatRightTopIcon info={item} />
                            </Col>
                        )
                    })}
                </Row>

                {/* Active Projects  */}
                <LeadListDashboard data={dashboardData?.leadList} />

            </Container>
        </DashboardLayout>
    )
}
export default ManagerDashboard;
