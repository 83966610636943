import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

export default function UserHistoryGraph({ data }) {
  // Extract data for each series
  const clients = data?.map((item) => item?.clients);
  const operatorSales = data?.map((item) => item.operatorSales);
  const operatorOperations = data?.map((item) => item.operatorOperations);
  const operatorAccounts = data?.map((item) => item.operatorAccounts);
  const expertInternal = data?.map((item) => item.expertInternal);
  const expertExternal = data?.map((item) => item.expertExternal);
  const dates = data?.map((item) => item.date);

  return (
    <BarChart
      series={[
        { data: clients, label: "Total Clients" },
        { data: operatorSales, label: "Operator (Sales)" },
        { data: operatorOperations, label: "Operator (Operations)" },
        { data: operatorAccounts, label: "Operator (Accounts)" },
        { data: expertInternal, label: "Expert (Internal)" },
        { data: expertExternal, label: "Expert (External)" },
      ]}
      height={290}
      xAxis={[{ data: dates, scaleType: "band" }]}
      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
    />
  );
}
