import React, { useEffect, useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../utils/data-tables/CustomDataTable";
import { getExpertQueriesAction, updateQueryStatusAction } from "../../services/action/common";
import { ColumnQueries } from "./ColumnQueries";
import CustomModal from "../utils/modals/CustomModal";
import ShowMessage from "./ShowMessage";
const Queries = () => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { queriesList } = useSelector((state) => state.commonReducer);
  const [recentResolved,setRecentResolved]=useState([])
  const [search,setSearch]=useState("")
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 15,
  });
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState("");
  const getList = ({ page = 1, firstTime = false,tempSearch=search }) => {
    dispatch(
      getExpertQueriesAction({
        userType: user?.userType?.toLowerCase(),
        page,
        data:{searchQuery:tempSearch},
        firstTime,
      })
    );
  };

  useEffect(() => {
    if (user) {
      getList({ page: 1, firstTime: true });
    }
  }, [dispatch, user]);

  const fetchMoreData = () => {
    setPageData((prev)=>({page:prev.page+1}))
    getList({ page: pageData?.page + 1 });
  };

  const updateQueryStatus=({leadId,queryId})=>{
    const tempObj={
      userType:user?.userType?.toLowerCase(),
      data:{
        leadId,
        queryId
      },
      cb:()=>setRecentResolved((prev)=>[...prev,queryId])
    }
    dispatch(updateQueryStatusAction(tempObj))
  }
  return (
    <DashboardLayout
    onSearch={(value) => {
      setSearch(value)
      setPageData({page:1})
      getList({ page: 1, firstTime: true,tempSearch:value });
    }}
    >
      <CustomModal
        open={showMessage}
        onClose={() => setShowMessage("")}
        content={() => <ShowMessage showMessage={showMessage} />}
      />
      <CustomDataTable
        fetchMoreData={fetchMoreData}
        isInfinitScroll={true}
        columns={ColumnQueries(setShowMessage,updateQueryStatus,recentResolved)}
        data={queriesList?.data}
      />
    </DashboardLayout>
  );
};

export default Queries;
