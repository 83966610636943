import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportsAction } from "../../../services/action/common";
import CurrencyBtn from "./CurrencyBtn";

const SelectDate = ({ reportType = "ORDERS",isShowCurrencyBtn }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedText, setSelectedText] = useState("Select Month");
  const { user } = useSelector((state) => state.authenticationReducer);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const selectDate = (date) => {
    if (date == "Complete") {
      setSelectedText(date);
      date = "";
    }
    dispatch(
      getReportsAction({
        userType: user?.userType?.toLowerCase(),
        data: {
          reportType: reportType,
          selectedMonth: date,
        },
      })
    );
    if (date) {
      setSelectedText(date);
    }
    setAnchorEl(null);
  };
  return (
    <div>
      {isShowCurrencyBtn && <CurrencyBtn />}
      <a href="#-" className="btn btn-white" onClick={handleClick}>
        {selectedText}
      </a>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => selectDate("Complete")}>Complete</MenuItem>
        <MenuItem onClick={() => selectDate("January")}>January</MenuItem>
        <MenuItem onClick={() => selectDate("February")}>February</MenuItem>
        <MenuItem onClick={() => selectDate("March")}>March</MenuItem>
        <MenuItem onClick={() => selectDate("April")}>April</MenuItem>
        <MenuItem onClick={() => selectDate("May")}>May</MenuItem>
        <MenuItem onClick={() => selectDate("June")}>June</MenuItem>
        <MenuItem onClick={() => selectDate("July")}>July</MenuItem>
        <MenuItem onClick={() => selectDate("August")}>August</MenuItem>
        <MenuItem onClick={() => selectDate("September")}>September</MenuItem>
        <MenuItem onClick={() => selectDate("October")}>October</MenuItem>
        <MenuItem onClick={() => selectDate("November")}>November</MenuItem>
        <MenuItem onClick={() => selectDate("December")}>December</MenuItem>
      </Menu>
    </div>
  );
};

export default SelectDate;
