import React, { useEffect, useState } from "react";
import Icons from "../../components/utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAction,
  loginAction,
  verifyGoogleTokenAction,
} from "../../services/action/authentication";
import { Link, useNavigate } from "react-router-dom";
import GoogleSignInButton from "./social-auth/GoogleSignInButton";
import { checkLogin } from "../../components/utils/functions/functions";
import {
  GoogleButtonBackground,
  GoogleButtonContent,
  GoogleButtonWrapperLogin,
  handleGoogleButtonHover,
  handleGoogleButtonLeave,
} from "./social-auth/StyledButton";
import { useAnimation } from "framer-motion";

const Login = () => {
  const [obj, setObj] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin, user } = useSelector((state) => state.authenticationReducer);

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    let tokens = localStorage.getItem("token");
    tokens && dispatch(getUserAction());
  }, []);

  useEffect(() => {
    checkLogin(navigate, user);
  }, [user]);

  const callBackSuccess = (res) => {
    checkLogin(navigate, res);
  };

  const loginNow = (e) => {
    e.preventDefault();
    let tempError = { ...errors };
    if (!obj.email) {
      tempError.email = "Email is required!";
    } else {
      tempError.email = "";
    }

    if (!obj.password) {
      tempError.password = "Password is required!";
    } else {
      tempError.password = "";
    }

    if (tempError.email || tempError.password) {
      setErrors(tempError);
      return false;
    }
    dispatch(loginAction({ data: obj, cb: callBackSuccess }));
  };

  const handleCallbackResponse = (response) => {
    if (!response.credential) {
      console.error("No credential returned from Google");
      return;
    }
    try {
      const payload = { token: response.credential };
      dispatch(
        verifyGoogleTokenAction({
          data: payload,
          cb: (res) => {
            if (res?.userType) {
              localStorage.setItem("token", res?.token);
              checkLogin(navigate, res);
            } else {
              const userObject = JSON.parse(
                atob(response?.credential.split(".")[1])
              );
              localStorage.setItem(
                "emailVerifyToken",
                JSON.stringify(userObject)
              );
              navigate("/auth/google");
            }
          },
        })
      );
    } catch (error) {
      console.error("Error decoding token or extracting user data:", error);
    }
  };
  const buttonControls = useAnimation();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <div className="max-w-lg w-full space-y-8 m-4 p-10 bg-white rounded-xl shadow-lg z-10">
        <div className="text-center">
          <img src="/images/logo.png" alt="" />
          {/* <h2 className="mt-6 text-3xl font-bold text-gray-900">
            Welcome Back!
          </h2> */}
          {/* <p className="mt-2 text-sm text-gray-600">Please sign in to your account</p> */}
        </div>
        <form className="mt-8 space-y-6" onSubmit={loginNow}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="relative">
            <label className="text-sm font-bold text-gray-700 tracking-wide">
              Email
            </label>
            <input
              className="w-full text-base py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              type="email"
              placeholder="mail@gmail.com"
              value={obj.email}
              onChange={(e) => setObj({ ...obj, email: e.target.value })}
            />
            {errors.email && (
              <span className="text-red-500 text-xs">{errors.email}</span>
            )}
          </div>
          <div className="mt-8 relative">
            <label className="text-sm font-bold text-gray-700 tracking-wide">
              Password
            </label>
            <input
              className="w-full text-base py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              type="password"
              placeholder="Enter your password"
              value={obj.password}
              onChange={(e) => setObj({ ...obj, password: e.target.value })}
            />
            {errors.password && (
              <span className="text-red-500 text-xs">{errors.password}</span>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>
            <div className="text-sm">
              <Link
                to="/reset-password"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center btn-primary text-gray-100 p-4 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
            >
              {isLogin ? <Icons title="loader" /> : "Sign in"}
            </button>
            <GoogleButtonWrapperLogin
              onMouseEnter={()=>handleGoogleButtonHover(buttonControls)}
              onMouseLeave={()=>handleGoogleButtonLeave(buttonControls)}
              animate={buttonControls}
            >
              {/* <GoogleButtonBackground
                initial={{ x: "-100%" }}
                animate={{ x: 0 }}
                transition={{ duration: 0.3 }}
              /> */}
              <GoogleButtonContent>
                <GoogleSignInButton onLoginSuccess={handleCallbackResponse} />
              </GoogleButtonContent>
            </GoogleButtonWrapperLogin>
            {/* <GoogleSignInButton isLoginPage={true} onLoginSuccess={handleCallbackResponse} /> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
