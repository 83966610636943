import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../../../../services/action/common";
import Icons from "../../../../utils/Icons";
import ImageUrlList from "../ImageUrlList";

const UploadRequirements = ({ imageUrls, setImageUrls }) => {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const { isImageLoading } = useSelector((state) => state.commonReducer);
  const [isDragging, setIsDragging] = useState(false); // State to handle drag-and-drop visuals

  const uploadImage = (files) => {
    const callBackImage = (res) => {
      let tempData = [...imageUrls];
      setImageUrls([...tempData, ...res?.imageUrls]);
    };
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
  };

  const handleFileInput = (e) => {
    uploadImage(e.target.files);
    e.target.value = null; // Clear file input after upload
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default to allow drop
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault(); // Prevent default behavior (opening the file in browser)
    setIsDragging(false); // Reset dragging state
    const files = e.dataTransfer.files; // Get dropped files
    if (files.length) {
      uploadImage(files); // Call the upload function
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false); // Reset dragging state when drag leaves the area
  };

  return (
    <div className="col-sm-12">
      <div
        className="delivery-box-input-file mt-2"
        onClick={() => fileRef.current?.click()}
        onDragOver={handleDragOver} // Handle drag over event
        onDrop={handleDrop} // Handle drop event
        onDragLeave={handleDragLeave} // Handle drag leave event
        style={{
          border:  "2px dashed #0a376a" , // Highlight drop area when dragging
          padding: "10px",
        }}
      >
        <p className="m-0 text-center">
          {isDragging ? "Drop files here..." : "Upload File"}
        </p>
        {isImageLoading && (
          <p className="m-0 text-center">
            <Icons title={"loader"} color="#0a376a" />
          </p>
        )}
      </div>
      <input
        type="file"
        onChange={handleFileInput}
        className="display-none"
        ref={fileRef}
        multiple={true}
      />
      {!!imageUrls?.length && (
        <ImageUrlList
          items={imageUrls}
          onDelete={(image) => {
            let filterDocument = imageUrls?.filter((item) => item !== image);
            setImageUrls(filterDocument);
          }}
        />
      )}
    </div>
  );
};

export default UploadRequirements;
