import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";

const DateTimeDatePicker = ({ value, setValue, minDate, maxDate, timezone = "Asia/Kolkata" }) => {
  // Convert the selected date to the specified timezone
  const selectedDate = value ? moment(value).tz(timezone).toDate() : null;

  // Handle date change and convert it back to UTC before storing
  const handleChange = (date) => {
    if (date) {
      const formattedDate = moment(date).tz(timezone).format();
      setValue(formattedDate);
    } else {
      setValue(null); // If cleared, set the value to null
    }
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleChange}
      minDate={minDate}
      maxDate={maxDate}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={30}
      timeCaption="Time"
      dateFormat="MMMM d, yyyy h:mm aa"
      className="form-control"
      isClearable
    />
  );
};

export default DateTimeDatePicker;
