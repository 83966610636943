import { put, call } from "redux-saga/effects";
import { appActions } from "../../action";
import { appApi } from "../../api";
import { Toaster } from "../../../components/Toaster";
import socket from "../../../components/socket/socket";

export function* sagaEngine({ type, payload }) {
  try {
    yield put({ type: `${appActions[type]}_START`, request: payload });
    if (type === appActions.START_CHAT) {
      // Emit a socket event to initiate chat
      socket.emit('initiate_chat', {
        leadId: payload?.leadDetail?._id,
        senderId: payload?.userId
      });

      yield put({
        type: `${appActions[type]}_SUCCESS`,
        payload
      });
    } else {
      const response = yield call(appApi[type], payload);
      payload?.cb && payload.cb(response?.data);

      response?.data?.msg && Toaster.success(response?.data?.msg);

      yield put({
        type: `${appActions[type]}_SUCCESS`,
        payload: response.data,
        request: payload,
      });
    }
  } catch (error) {
    payload?.errorCb && payload.errorCb(error?.response?.data);
    error?.response?.data?.msg && Toaster.error(error?.response?.data?.msg);
    error?.message && Toaster.error(error?.message);

    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      yield put({ type: `${appActions[type]}_FAIL` });
    }
    yield put({ type: `${appActions[type]}_FAIL` });
  }
}
