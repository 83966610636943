// import node module libraries
import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import CustomModal from "../../utils/modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import FormEditLeadGenerate from "./components/FormEditLeadGenerate";
import HeaderChips from "./components/HeaderChips";
import Icons from "../../utils/Icons";
import FullScreenModal from "../../utils/modals/FullScreenModal";
import PersonalCard from "../../card/PersonalCard";
import LeadCard from "../../card/LeadCard";
import { OrdertFilter, OrdertFilterExpert } from "../../utils/TeamsData";
import {
  requestForAssignLeadAction,
  startChatAction,
} from "../../../services/action/common";
import RangeDateFilter from "./components/RangeDateFilter";
import AssignExpert from "./components/assign-to-expert/AssignExpert";
import RequestLeadId from "./components/expert/RequestLeadId";
import CustomDataTable from "../../utils/data-tables/CustomDataTable";
import { ColumnOrderList } from "./components/ColumnOrderList";
import Buttons from "../../utils/buttons/Buttons";

const OrderListComponent = ({
  getList,
  TeamsData,
  listTitle = "Lead List",
  isOpenLeadOnly = false,
  pageData,
  setPageData,
  setFilter,
  filter,
  getAllLiveUser,
  setDateRanges = {},
  dateRanges = {},
}) => {
  const [leadDetails, setLeadDetails] = useState({
    modal: false,
    edit: false,
    preview: false,
    data: "",
  });

  const [hideAppliedLead, setHideApplied] = useState(false);
  const exportInExcelRef = useRef();

  const [showExpertAssign, setShowExpertAssign] = useState("");
  const [clientId, setClientId] = useState("");
  const [expertId, setExpertId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const { user } = useSelector((state) => state.authenticationReducer);
  const [requestLeadId, setRequestLeadId] = useState("");
  const dispatch = useDispatch();
  const isExpert = user?.userType === "EXPERT";

  const afterSave = () => {
    setLeadDetails({});
    setPageData({ page: 1 });
    getList({ currentPage: 1, filter, firstTime: true });
  };

  const onSelectFilter = (filter) => {
    setPageData({ page: 1 });
    getList({ currentPage: 1, filter, firstTime: true });
  };

  const isMangerOrOperator =
    user?.userType === "OPERATOR" ||
    user?.userType === "MANAGER" ||
    user?.userType === "ADMIN" ||
    user?.userType === "SUPER_ADMIN";

  const isMangerOrAdmin =
    user?.userType === "MANAGER" ||
    user?.userType === "ADMIN" ||
    user?.userType === "SUPER_ADMIN";

  const fetchMoreData = () => {
    setPageData({ page: pageData?.page + 1 });
    getAllLiveUser({ currentPage: pageData?.page + 1, firstTime: false });
  };

  const startChat = (item) => {
    dispatch(startChatAction(item));
  };

  const removeFromRequestExpert = (lead) => {
    dispatch(
      requestForAssignLeadAction({
        userType: user?.userType?.toLowerCase(),
        data: {
          leadId: lead?._id,
          expertId: user?._id,
          comment: "Remove",
        },
        cb: () => {
          setRequestLeadId("");
          afterSave();
        },
      })
    );
  };
  return (
    <Card className="h-100">
      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={clientId}
        onClose={() => setClientId("")}
        content={() => (
          <PersonalCard
            hideAction={true}
            onDeleteClick={(_) => {}}
            onEditClick={(_) => {}}
            data={clientId}
            close={() => setClientId("")}
          />
        )}
      />
      <CustomModal
        open={showExpertAssign}
        onClose={() => setShowExpertAssign("")}
        content={() => (
          <AssignExpert
            openLead={showExpertAssign}
            showMailOption={true}
            onClose={() => {
              setShowExpertAssign("");
              afterSave();
            }}
          />
        )}
      />

      <CustomModal
        open={requestLeadId}
        onClose={() => setRequestLeadId("")}
        headerShown={false}
        content={() => (
          <RequestLeadId
            lead={requestLeadId}
            onClose={() => {
              setRequestLeadId("");
              afterSave();
            }}
          />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={expertId}
        onClose={() => setExpertId("")}
        content={() => (
          <PersonalCard
            hideAction={true}
            onDeleteClick={(_) => {}}
            onEditClick={(_) => {}}
            data={expertId}
            close={() => setExpertId("")}
          />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={leadId}
        onClose={() => setLeadId("")}
        content={() => (
          <LeadCard
            hideAction={true}
            onDeleteClick={(_) => {}}
            onEditClick={(data) =>
              setLeadDetails({ edit: true, data, modal: true })
            }
            data={leadId}
            close={() => setLeadId("")}
          />
        )}
      />
      <CustomModal
        title={"Generate Lead"}
        bgContent="dark-bg"
        open={leadDetails.modal}
        onClose={() => setLeadDetails({})}
        content={() => (
          <FormEditLeadGenerate
            page="order"
            data={leadDetails?.data}
            close={afterSave}
          />
        )}
      />

      <Card.Header className="bg-white py-4">
        <h4 className="mb-0">
          {listTitle}{" "}
          {isOpenLeadOnly && (
            <Buttons
              size="small"
              onClick={() => setHideApplied(!hideAppliedLead)}
              title={hideAppliedLead ? "Show Applied" : "Hide Applied"}
            />
          )}
          {!isOpenLeadOnly && (
            <span
              className={`filter-icon ${showFilter && "applied-filter-icon"}`}
              onClick={() => setShowFilter(!showFilter)}
            >
              <Icons title={"filter"} />
            </span>
          )}
          {isMangerOrOperator && (
            <RangeDateFilter
              setPageData={setPageData}
              getList={getList}
              filter={filter}
              setDateRanges={setDateRanges}
              dateRanges={dateRanges}
            />
          )}
          {isMangerOrAdmin && (
            <Buttons
              style={{ marginLeft: 10 }}
              title={"Excel"}
              onClick={() => exportInExcelRef.current?.click()}
            />
          )}
        </h4>
      </Card.Header>
      {!isOpenLeadOnly && (
        <HeaderChips
          list={isExpert ? OrdertFilterExpert : OrdertFilter}
          onSelectFilter={onSelectFilter}
          filter={filter}
          setFilter={setFilter}
          showFilter={showFilter}
        />
      )}
      <CustomDataTable
        columns={ColumnOrderList(
          startChat,
          afterSave,
          setShowExpertAssign,
          isOpenLeadOnly,
          user,
          setClientId,
          setRequestLeadId,
          removeFromRequestExpert,
          isMangerOrAdmin
        )}
        downLoadBtnRef={exportInExcelRef}
        isInfinitScroll={true}
        fetchMoreData={fetchMoreData}
        data={
          hideAppliedLead
            ? TeamsData?.data?.filter((item) => !item?.expertRequest)
            : TeamsData?.data
        }
      />
    </Card>
  );
};

export default OrderListComponent;
