export const TeamsData = [
    {
       id:1,
       name : "Anita Parmar",
       email : "anita@example.com",
       role: "Front End Developer",
       lastActivity: '3 May, 2023',
       image:'/images/avatar/avatar-2.jpg' 
    },
    {
        id:2,
        name : "Jitu Chauhan",
        email : "jituchauhan@example.com",
        role: "Project Director",
        lastActivity: 'Today',
        image:'/images/avatar/avatar-1.jpg' 
     },
     {
        id:3,
        name : "Sandeep Chauhan",
        email : "sandeepchauhan@example.com",
        role: "Full- Stack Developer",
        lastActivity: 'Yesterday',
        image:'/images/avatar/avatar-3.jpg' 
     },
     {
        id:4,
        name : "Amanda Darnell",
        email : "amandadarnell@example.com",
        role: "Account Manager",
        lastActivity: '3 May, 2023',
        image:'/images/avatar/avatar-4.jpg' 
     },
     {
        id:3,
        name : "Patricia Murrill",
        email : "patriciamurrill@example.com",
        role: "Digital Marketer",
        lastActivity: '3 May, 2023',
        image:'/images/avatar/avatar-5.jpg' 
     },
     {
        id:4,
        name : "Darshini Nair",
        email : "darshininair@example.com",
        role: "Front End Developer",
        lastActivity: '3 May, 2023',
        image:'/images/avatar/avatar-6.jpg' 
     }
];
export default TeamsData;


export  const OrdertFilter = [
   { label: "All Orders", value: "ALL_ORDERS" },
   { label: "Open", value: "OPEN" },
   { label: "Assigned", value: "ASSIGNED" },
   { label: "Done", value: "DONE" },
   { label: "Delivered", value: "DELIVERED" },
   { label: "Re Open", value: "REOPEN" },
   { label: "On Hold", value: "ONHOLD" },
   { label: "Refund", value: "REFUND" },
 ];

 export  const OrdertFilterExpert = [
  { label: "All Orders", value: "ALL_ORDERS" },
  { label: "Open", value: "OPEN" },
  { label: "Completed Orders", value: "COMPLETED" },
  { label: `Today's Deadline orders`, value: "TODAY_DEADLINE" },
  { label: "Missed Order", value: "MISSED" },
  { label: "Assigned", value: "ASSIGNED" },
  { label: "Delivered", value: "DELIVERED" },
  { label: "Re Open", value: "REOPEN" },
  { label: "On Hold", value: "ONHOLD" },
  { label: "Refund", value: "REFUND" },
  { label: "Done", value: "DONE" },
];

 export  const LeadFilter = [
   { label: "All Leads", value: "ALL_LEADS" },
   { label: "New", value: "NEW" },
   { label: "In progress", value: "INPROGRESS" },
   { label: "Paid", value: "PAID" },
   { label: "Closed", value: "CLOSED" },
 ];

 
 export  const LeadStatus = [
   { label: "New", value: "NEW" },
   { label: "In progress", value: "INPROGRESS" },
   { label: "Paid", value: "PAID" },
   { label: "Closed", value: "CLOSED" },
 ];

 export  const OrderStatus = [
   { label: "Open", value: "OPEN" },
   { label: "Assigned", value: "ASSIGNED" },
   { label: "Paid", value: "PAID" },
   { label: "Delivered", value: "DELIVERED" },
   { label: "Re Open", value: "REOPEN" },
   { label: "On Hold", value: "ONHOLD" },
   { label: "Refund", value: "REFUND" },   
   { label: "Done", value: "DONE" },
 ];
 
 export const  currancyList=[
   {label:"Currency",value:""},
   {label:"USD",value:"USD"},
   {label:"INR",value:"INR"},
   {label:"GBP",value:"GBP"},
   {label:"AUD",value:"AUD"},
   {label:"EUR",value:"EUR"},
   {label:"SGD",value:"SGD"},
   {label:"CAD",value:"CAD"},
   {label:"MYR",value:"MYR"},
   {label:"NRP",value:"NRP"},
 ];
 export const  paymentType=[
  {label:"Payment Type",value:""},
  {label:"Razorpay",value:"razorpay"},
  {label:"Strip",value:"stripe"},
];

 export const paymentTypeList=[
   {label:"",value:""},
   {label:"Full",value:"FULL"},
   {label:"Partial",value:"PARTIAL"},
 ]

 export const planTypeList=[
   {label:"",value:""},
   {label:"Platinum",value:"Platinum"},
   {label:"Silver",value:"Silver"},
   {label:"Gold",value:"Gold"},
 ]


 export const defaultAvatarImg="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg";