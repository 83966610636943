import React, { useState } from "react";
import { Card} from "react-bootstrap";
import Buttons from "../../utils/buttons/Buttons";
import CustomModal from "../../utils/modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import FormEditLeadGenerate from "./components/FormEditLeadGenerate";
import HeaderChips from "./components/HeaderChips";
import Icons from "../../utils/Icons";
import FullScreenModal from "../../utils/modals/FullScreenModal";
import PersonalCard from "../../card/PersonalCard";
import { LeadFilter } from "../../utils/TeamsData";
import { startChatAction } from "../../../services/action/common";
import RangeDateFilter from "./components/RangeDateFilter";
import AssignExpert from "./components/assign-to-expert/AssignExpert";
import { ColumnLeadList } from "./components/ColumnLeadList";
import CustomDataTable from "../../utils/data-tables/CustomDataTable";

const LeadListComponent = ({
  getList,
  TeamsData,
  listTitle = "Lead List",
  addButtonTitle = "Add New Lead",
  setPageData,
  pageData,
  filter,
  setFilter,
  setDateRanges={},
  dateRanges={}
}) => {
  const [leadDetails, setLeadDetails] = useState({
    modal: false,
    edit: false,
    preview: false,
    data: "",
  });
  const [showExpertAssign, setShowExpertAssign] = useState("");
  const [clientId, setClientId] = useState("");
  const [expertId, setExpertId] = useState("");

  const [showFilter, setShowFilter] = useState(false);

  const { user } = useSelector((state) => state.authenticationReducer);
  
  const dispatch = useDispatch();

  const afterSave = () => {
    setLeadDetails({});
    setPageData({page:1})
    getList({ currentPage:1, filter, isEmptylist: false });
  };


  const onSelectFilter = (filter) => {
    setPageData({page:1})
    getList({ currentPage:1, filter });
  }

  const isMangerOrOperator =
    user?.userType === "OPERATOR" || user?.userType === "MANAGER";


    const startChat=(item)=>{
      dispatch(
        startChatAction({ leadDetail: item, userId: user?._id })
      )
    }

    const fetchMoreData = () => {
      setPageData({ page: pageData?.page + 1 });
      getList({ currentPage: pageData?.page + 1, firstTime: false });
    };
  return (
    <Card className="h-100">
      <CustomModal
        open={showExpertAssign}
        onClose={() => setShowExpertAssign("")}
        content={() => (
          <AssignExpert
            openLead={showExpertAssign}
            onClose={() => {
              setShowExpertAssign("");
              afterSave();
            }}
          />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={clientId}
        onClose={() => setClientId("")}
        content={() => (
          <PersonalCard
            hideAction={true}
            onDeleteClick={(data) => {}}
            onEditClick={(data) => {}}
            data={clientId}
            close={() => setClientId("")}
          />
        )}
      />

      <FullScreenModal
        title={"Expert Details"}
        bgContent="dark-bg"
        size="xl"
        open={expertId}
        onClose={() => setExpertId("")}
        content={() => (
          <PersonalCard
            hideAction={true}
            onDeleteClick={(data) => {}}
            onEditClick={(data) => {}}
            data={expertId}
            close={() => setExpertId("")}
          />
        )}
      />

      <CustomModal
        title={addButtonTitle}
        bgContent="dark-bg"
        open={leadDetails.modal}
        onClose={() => setLeadDetails({})}
        content={() => (
          <FormEditLeadGenerate  data={leadDetails?.data} close={afterSave} />
        )}
      />

      <Card.Header className="bg-white py-4">
        <h4 className="mb-0">
          {listTitle}{" "}
          <Buttons
            onClick={() => setLeadDetails({ modal: true, edit: false })}
            title={addButtonTitle}
            icon="plus"
          />{" "}
          <span
            className={`filter-icon ${showFilter && "applied-filter-icon"}`}
            onClick={() => setShowFilter(!showFilter)}
          >
            <Icons title={"filter"} />
          </span>
          {isMangerOrOperator && (
            <RangeDateFilter setDateRanges={setDateRanges} dateRanges={dateRanges} getList={getList} filter={filter} />
          )}
        </h4>
      </Card.Header>
      <HeaderChips
        list={LeadFilter}
        onSelectFilter={onSelectFilter}
        filter={filter}
        setFilter={setFilter}
        showFilter={showFilter}
      />
      <CustomDataTable
        columns={ColumnLeadList(
          afterSave,
          setShowExpertAssign,
          user,
          startChat,
          setClientId
        )}
        isInfinitScroll={true}
        fetchMoreData={fetchMoreData}
        data={TeamsData?.data}
      />
    </Card>
  );
};

export default LeadListComponent;
