import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function OrderHistoryGraph({data}) {
  

  // Extract data for each series
  const totalOrdersData = data?.map(item => item?.totalOrders);
  const orderCompletedData = data?.map(item => item.orderCompleted);
  const missedOrderData = data?.map(item => item.missedOrder);
  const inProgressOrderData = data?.map(item => item.inProgressOrder);
  const dates = data?.map(item => item.date);

  return (
    <BarChart
      series={[
        { data: totalOrdersData, label: 'Total Orders' },
        { data: orderCompletedData, label: 'Order Completed' },
        { data: missedOrderData, label: 'Missed Orders' },
        { data: inProgressOrderData, label: 'In Progress Orders' }
      ]}
      height={290}
      xAxis={[{ data: dates, scaleType: 'band' }]}
      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
    />
  );
}
