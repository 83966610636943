export const ColumnContactList = () => {
    return [
      {
        name: "Sr. No",
        selector: (_,index) => index+1,
        sortable: true,
        width: "119px",
      },
      {
        name: "Name",
        selector: (row) => row?.name || "NA",
        sortable: true,
        maxWidth: "219px",
      },
      {
        name: "Number",
        selector: (row) => row?.number || "NA",
        sortable: true,
        maxWidth: "219px",
      },
      {
        name: "Email",
        selector: (row) => (
          <span className={`${(typeof row?.email ==="string" && row?.email) ? "text-success" : "text-danger"}`}>
           {(typeof row?.email ==="string" && row?.email)?row?.email : "NA"}
          </span>
        ),
        width: "89px",
        sortable: true,
      },
      
    ];
  };
  