import React from "react";
import { useSelector } from "react-redux";

const DownloadAllFiles = ({ message ,messageId}) => {
  const { leadDetail } = useSelector((state) => state.commonReducer);
  const baseUrl=process.env.REACT_APP_API_URL || "https://portal.be.clickinpedia.io/"
  const link=`${baseUrl}download-zip?orderId=${leadDetail?.orderId}&leadId=${leadDetail?._id}&token=${localStorage?.getItem("token")}&messageId=${messageId}`;
  return (
    message?.includes("file-list") && (
      <div className="download-all-btn">
        <a href={link}>
          <i class="fa fa-download" aria-hidden="true"></i>
        </a>
      </div>
    )
  );
};

export default DownloadAllFiles;
