import React, { useEffect, useState } from "react";
import LeadListComponent from "../../components/manager/leads/LeadListComponent";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsAction } from "../../services/action/manager";
const LeadListSA = () => {
  const dispatch = useDispatch();
  const { leads } = useSelector((state) => state.managerReducer);
  const initialDate = {
    clientStartDate: null,
    clientEndDate: null,
    expertStartDate: null,
    expertEndDate: null,
    leadStartDate: null,
    leadEndDate: null,
  };
  const [dateRanges, setDateRanges] = useState(initialDate);
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 15,
  });
  const [filters, setFilter] = useState(["ALL_LEADS"]);

  const getList = ({
    currentPage = pageData?.page,
    firstTime = false,
    isEmptylist = true,
    search = "",
    filter = filters,
    sortConfig,
    dateFilter = dateRanges,
  }) => {
    dispatch(
      getLeadsAction({
        userType: "super_admin",
        firstTime,
        data: {
          sortConfig,
          search,
          page: currentPage,
          isEmptylist,
          dateFilter: {
            clientDeadline: {
              startDate: dateFilter.clientStartDate,
              endDate: dateFilter.clientEndDate,
            },
            expertDeadline: {
              startDate: dateFilter.expertStartDate,
              endDate: dateFilter.expertEndDate,
            },
            leadRegistration: {
              startDate: dateFilter.leadStartDate,
              endDate: dateFilter.leadEndDate,
            },
          },
          leadStatus: filter,
        },
      })
    );
  };

  useEffect(() => {
    getList({ currentPage: 1, firstTime: true });
  }, []);
  return (
    <DashboardLayout
      onSearch={(value) => {
        setPageData({ page: 1 });
        getList({ search: value, firstTime: true, currentPage: 1 });
      }}
    >
      <LeadListComponent
       setDateRanges={setDateRanges}
       dateRanges={dateRanges}
        setPageData={setPageData}
        pageData={pageData}
        getList={getList}
        TeamsData={leads}
        filter={filters}
        setFilter={setFilter}
      />
    </DashboardLayout>
  );
};

export default LeadListSA;
