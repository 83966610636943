import React, { useRef } from 'react'
import CustomDataTable from '../../utils/data-tables/CustomDataTable'
import { ColumnContactList } from './ColumnContactList'
import Buttons from '../../utils/buttons/Buttons'

const UserConatactContent = ({data}) => {
    const downloadRef=useRef();
  return (
    <div className='container'>
      <div className='row'>
      <div className='col-12 text-right'>
        <Buttons title={"Export in excel"} onClick={()=>downloadRef?.current?.click()}/>
        </div>
        <div className='col-12'>
            <CustomDataTable
            isNotOrderId={true}
             data={data}
              downLoadBtnRef={downloadRef}
             columns={ColumnContactList()}
            />
        </div>
      </div>
    </div>
  )
}

export default UserConatactContent