import React, { Fragment } from "react";
import { currancyList, paymentTypeList } from "../../../utils/TeamsData";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import DateTimeDatePicker from "../../../utils/date-picker/DateTimeDatePicker";
const FormPayment = ({
  page,
  isManager,
  errors,
  register,
  getWatch,
  STATUS,
  isAdminOrSuperAdmin,
  isOperator,
  data,
  setValue,
}) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { expertListOption } = useSelector((state) => state.commonReducer);
  const isClient = user?.userType === "CLIENT";

  // Handlers for date changes
  const handleClientDeadlineChange = (formattedDate) => {
    setValue("clientDeadline", formattedDate);
  };

  const handleExpertDeadlineChange = (formattedDate) => {
    setValue("expertDeadline", formattedDate);
  };
  return (
    <>
      <div className="col-sm-6">
        <label className={errors?.clientDeadline && "text-danger"}>
          Client Deadline<span className="text-danger">*</span>
        </label>
        <DateTimeDatePicker
          value={getWatch.clientDeadline}
          setValue={handleClientDeadlineChange}
          minDate={new Date()}
          timezone="Asia/Kolkata"
        />
      </div>
      {getWatch[STATUS] !== "NEW" && page === "order" && (
        <div className="col-sm-6">
          <label className={errors?.expertDeadline && "text-danger"}>
            Expert Deadline
            {getWatch?.expert && <span className="text-danger">*</span>}
          </label>
          <DateTimeDatePicker
            value={getWatch.expertDeadline}
            setValue={handleExpertDeadlineChange}
            minDate={new Date()}
            maxDate={
              getWatch?.clientDeadline &&
              moment(getWatch?.clientDeadline).tz("Asia/Kolkata").toDate()
            }
            timezone="Asia/Kolkata"
          />
        </div>
      )}
      {(page === "order" || getWatch[STATUS] === "PAID") && (
        <>
          <div className="col-sm-6">
            <label className={errors?.currancy && "text-danger"}>
              Currency<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              {...register("currancy", {
                required: true,
              })}
              disabled={!isManager && !isOperator && !isAdminOrSuperAdmin}
            >
              {currancyList.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-6">
            <label className={errors?.paymentType && "text-danger"}>
              Payment Type<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              {...register("paymentType", {
                required: true,
              })}
              disabled={!isManager && !isOperator && !isAdminOrSuperAdmin}
            >
              {paymentTypeList.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-sm-6">
            <label className={errors?.totalPayment && "text-danger"}>
              Total Payment<span className="text-danger">*</span>
            </label>
            <input
              {...register("totalPayment", {
                required: true,
              })}
              disabled={!isManager && !isOperator && !isAdminOrSuperAdmin}
              type="number"
              className="form-control"
            />
          </div>
          <div className="col-sm-6">
            <label>Payment Received</label>
            <input
              {...register("paymentRecieved", {
                required: true,
              })}
              disabled={!isManager && !isOperator && !isAdminOrSuperAdmin}
              type="number"
              className="form-control"
            />
          </div>
          {page === "order" && !isClient && (
            <Fragment>
              <div className="col-sm-6">
                <label className={errors?.expert && "text-danger"}>
                  Expert{" "}
                  {getWatch?.orderStatus !== "OPEN" && (
                    <span className="text-danger">*</span>
                  )}
                </label>
                <select
                  className="form-control"
                  {...register("expert", {
                    required: getWatch?.orderStatus !== "OPEN",
                  })}
                  disabled={!isManager && !isOperator && !isAdminOrSuperAdmin}
                >
                  <option value={""}></option>
                  {expertListOption?.map((item, index) => (
                    <option
                      selected={getWatch?.expert === item?.value}
                      key={index}
                      value={item?.value}
                    >
                      {item?.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6">
                <label className={errors?.expertAmount && "text-danger"}>
                  Expert Amount
                  {getWatch?.orderStatus !== "OPEN" && (
                    <span className="text-danger">*</span>
                  )}
                </label>
                <input
                  {...register("expertAmount", {
                    required: getWatch?.orderStatus !== "OPEN",
                  })}
                  type="text"
                  disabled={!isManager && !isOperator && !isAdminOrSuperAdmin}
                  className="form-control"
                />
              </div>
            </Fragment>
          )}
        </>
      )}
    </>
  );
};

export default FormPayment;
